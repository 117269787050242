import {createSlice} from "@reduxjs/toolkit"

import {Device} from "@/domain/types/device"
import {RoIBase} from "@/domain/types/rois/roi"
import {Setup} from "@/domain/types/setup"
import {Usecase} from "@/domain/types/usecase"

import Camera, {CameraRaw} from "../../../domain/types/camera"

interface initialInterface {
    setups: Setup[],
    cameraList: Camera[],
    useCases: Usecase[],
    devices: Device[]

    currentSetup: Setup
    currentCamera: CameraRaw,
    areas: RoIBase[],

    filter: {
        classes: string[],
        usecase_id: string | undefined,
        device_name: string | undefined,
        search: string | undefined
    }
}

const initialState: initialInterface = {
    setups: [],
    cameraList: [],
    useCases: [],
    devices: [],

    currentSetup: {} as Setup,
    currentCamera: {} as CameraRaw,
    areas: [],

    filter: {
        search: undefined,
        classes: [],
        usecase_id: undefined,
        device_name: undefined,
    }
}

const setupsSlice = createSlice({
    name: 'setups',
    initialState,
    reducers: {
        updateSetups: (state = initialState, action) => ({
            ...state,
            ...action.payload
        }),
        setCurrentSetup: (state = initialState, action) => {
            state.currentSetup = action.payload
        },
        setCameraList: (state = initialState, action) => {
            state.cameraList = action.payload
        },
        clearFilter: (state = initialState, ) => ({
            ...state,
            filter: {
                search: undefined,
                classes: [],
                usecase_id: undefined,
                device_name: undefined,
            }
        })
    }
})

export const {actions: setupsActions} = setupsSlice
export const setupsReducer = setupsSlice.reducer
