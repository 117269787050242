export type Setup = {
    setup_name: string,
    setup_id: number,
    device_id: string
    usecase_id: string,
    classes: SetupClasses[],
    cameras: number
}

export type SetupClasses = {
    class_id: string
}

export class NewSetup {
    constructor(
        public setup_name: string,
        public device_id: string,
        public usecase_id: string,
        public classes: SetupClasses[]) {}
}

export const toSetup = (setup: Partial<Setup>): Partial<Setup> => {
    return {
        setup_name: setup.setup_name,
        device_id: setup.device_id,
        usecase_id: setup.usecase_id,
        classes: setup.classes,
    }
}

export enum SetupUseCases {
    "HP" = "Helpless Person",
    "PIRA" = "Person in Restricted Area",
    "PT" = "Public Transport",
    "UL" = "Unattended luggage"
}
