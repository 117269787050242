import {ScaleIconAlertInformation} from '@telekom/scale-components-react'
import React, {ReactNode} from 'react'

import styles from './styles.module.scss'

const {
  'information-badge' : informationBadgeCss,
  'information-badge__text' : informationBadgeTextCss
} = styles

type Props = {
  children?: ReactNode
}

const InformationBadge = ({children}: Props) => {
  return <div className={informationBadgeCss}>
    <ScaleIconAlertInformation size={22} />
    <span className={informationBadgeTextCss}>
      {children}
    </span>
  </div>
}

export default InformationBadge
