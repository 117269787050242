import {expect} from 'chai'

import {RoiRaw} from '../../roi-raw'
import RoIType from '../../roi-type'
import RoI from '../roi'
import CountingLineCoordinates from './counting-line.coordinates'
import CountingLineRoiEditable from './counting-line.roi-editable'

class CountingLineRoi extends RoI<CountingLineCoordinates> {
  static SCALE_FACTOR = 1000
  static EDITABLE_CLASS = CountingLineRoiEditable
  static ROI_TYPE: RoIType = 'CountingLine'

  get type():RoIType {
    return CountingLineRoi.ROI_TYPE
  }

  scaleFactor(): number {
    return CountingLineRoi.SCALE_FACTOR
  }

  createEditable(): CountingLineRoiEditable {
    return new CountingLineRoiEditable(this)
  }

  getLength(): number {
    const {line, arrow} = this.coordinates
    const array = [ line.dot1, line.dot2, arrow.dot1, arrow.dot2 ].filter( dot => !!dot )
    return array.length
  }

  testJSON(obj: RoiRaw) {
    try {
      super.testJSON(obj)
      const coordinates:CountingLineCoordinates = JSON.parse(obj.roi_coordinates)
      expect( coordinates ).to.be.an('object')
      expect( coordinates.line ).to.be.an('object')
      expect( coordinates.arrow ).to.be.an('object')
      this.testDot(coordinates.line.dot1)
      this.testDot(coordinates.line.dot2)
    } catch (e) {
      return false
    }
    return true
  }
}

export default CountingLineRoi
