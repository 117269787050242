import {ScaleTelekomFooter, ScaleTelekomFooterContent} from "@telekom/scale-components-react"
import React from 'react'
import {Link} from "react-router-dom"

import {footer_routes} from "../entities"

export const Footer = () => {
    return (
      <ScaleTelekomFooter type={'minimal'}>
        <ScaleTelekomFooterContent>
          <span slot={"notice"}>© Deutsche Telekom AG</span>
          <ul slot={'navigation'}>
            {footer_routes.map((item) => {
                        return <li key={item.href}>
                          <Link to={item.href}>{item.name}</Link>
                        </li>
                    })}
          </ul>
        </ScaleTelekomFooterContent>
      </ScaleTelekomFooter>
    )
}
