import React from 'react'
import {useDispatch, useSelector} from "react-redux"
import {Link, useLocation} from "react-router-dom"

import {CustomModal} from "@/components"
import {appActions} from "@/model/slices/app-slice"
import {TStore} from "@/store"

import {RoutePath} from "../providers/router-provider/router-config"

export const CookieModal = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const cookieAuthModal = useSelector((state: TStore) => state.appReducer.cookieAuthModal)
    const cookieAuth = Boolean(localStorage.getItem('cookie_auth'))

    const acceptCookies = () => {
        dispatch(appActions.updateApp({cookieAuthModal: false}))
        localStorage.setItem('cookie_auth', 'true')
    }

    const isShowModal = cookieAuthModal &&
        ((location.pathname !== '/datenschutzhinweise') && (location.pathname !== '/login')) && !cookieAuth

    return (
      <>
        {isShowModal && <CustomModal
          title={'Cookies policy'}
          content={<div>
            Diese Website verwendet ausschließlich nur die technisch erforderlichen Cookies,
            um Ihnen den bestmöglichen Service zu gewährleisten. Weitere Informationen finden
            Sie <Link to={RoutePath.datenschutzhinweise}>hier</Link>.
          </div>}
          okContent={'Accept'}
          isDisabledOverlay={cookieAuthModal}
          onAction={() => acceptCookies()}
          isShowClose={false}
          position={'bottom'}
        />}
      </>
    )
}
