import Dot from '../../dot'
import RestrictedAreaCoordinates from '../restricted-area/restricted-area.coordinates'
import RestrictedAreaRoiEditable from '../restricted-area/restricted-area.roi-editable'
import {IRoI} from '../roi'
import CountingAreaRoiEditable from './counting-area.roi-editable'

type IRoiCounting = IRoI<RestrictedAreaCoordinates> & {
  countingRoi: CountingAreaRoiEditable
}

class CountingAreaPartRoiEditable extends RestrictedAreaRoiEditable {
  private readonly fieldName: 'inside' | 'outside'
  private readonly isFinishedName: 'insideFinished' | 'outsideFinished'

  constructor( private countingRoi: CountingAreaRoiEditable,
               private outside: boolean ) {
    super( {
             id: outside ? -100 : -200,
             color: '',
             coordinates: outside
               ? countingRoi.coordinates.outside
               : countingRoi.coordinates.inside,
             name: countingRoi.name,
             type: 'RestrictedArea',
             countingRoi
           } as IRoiCounting)
    this.fieldName = this.outside ? 'outside' : 'inside'
    this.isFinishedName = this.outside  ? 'outsideFinished' : 'insideFinished'
  }

  protected init( roi: IRoiCounting ) {
    this.countingRoi = roi.countingRoi
    super.init( roi )
  }

  isInside() {
    return !this.outside
  }

  isOutside() {
    return this.outside
  }


  getCoordinatesWithIds( coordinates: RestrictedAreaCoordinates ): RestrictedAreaCoordinates {
    this.countingRoi[this.fieldName] = coordinates
    return coordinates
  }

  // @ts-ignore
  // for some reason TS doesn't allow us ot override this method. Js allows it
  get coordinates() {
    if (this.countingRoi) {
      return this.countingRoi.coordinates[this.fieldName]
    }
  }

  set coordinates(coordinates: Dot[]) {
    if (this.countingRoi) {
      this.countingRoi.coordinates[this.fieldName] = coordinates
    }
  }

  // @ts-ignore
  // for some reason TS doesn't allow us ot override this method. Js allows it
  get name() {
    const prefix = this.outside ? 'out' : 'in'
    const name = this.countingRoi.name
    return prefix + (name ? ': ' + name : '')
  }

  set name(name: string) {
    if (this.countingRoi) {
      this.countingRoi.name = name
    }
  }

  // @ts-ignore
  // for some reason TS doesn't allow us ot override this method. Js allows it
  get color() {
    return this.countingRoi.color
  }

  set color(color: string) {
    if (this.countingRoi) {
      this.countingRoi.color = color
    }
  }


  getLength(): number {
    return this.countingRoi.coordinates[this.fieldName].length
  }

  freeze() {
    super.freeze()
    this.countingRoi[this.isFinishedName] = true
  }

  unfreeze() {
    super.unfreeze()
    this.countingRoi[this.isFinishedName] = false
  }

  isFinished(): boolean {
    return this.countingRoi[this.isFinishedName]
  }

  clear() {
    this.countingRoi.clear()
  }

  clearCurrent() {
    this.countingRoi.coordinates[this.fieldName].length = 0
  }
}

export default CountingAreaPartRoiEditable
