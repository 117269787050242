import RoIType from '../roi-type'
import Coordinates from './coordinates'
import CountingAreaCoordinates from './counting-area/counting-area.coordinates'
import CountingAreaRoi from './counting-area/counting-area.roi'
import CountingLineCoordinates from './counting-line/counting-line.coordinates'
import CountingLineRoi from './counting-line/counting-line.roi'
import RestrictedAreaCoordinates from './restricted-area/restricted-area.coordinates'
import RestrictedAreaRoi from './restricted-area/restricted-area.roi'
import RoI from './roi'

type RoIInheritor = CountingLineRoi | RestrictedAreaRoi | CountingAreaRoi
type RoIInheritorType = typeof CountingLineRoi | typeof RestrictedAreaRoi | typeof CountingAreaRoi

const CLZ: { [key in RoIType]: RoIInheritorType} = {
  'CountingLine' : CountingLineRoi,
  'RestrictedArea': RestrictedAreaRoi,
  'CountingArea': CountingAreaRoi
}

export {
  CLZ,
  type Coordinates,
  type CountingAreaCoordinates,
  CountingAreaRoi,
  type CountingLineCoordinates,
  CountingLineRoi,
  type RestrictedAreaCoordinates,
  RestrictedAreaRoi,
  RoI,
  type RoIInheritor,
  type RoIInheritorType}
