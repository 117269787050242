import React from 'react'

import Loader from "../loader"
import styles from "./styles.module.scss"

const {
    'page-loader': pageLoader,
} = styles

const PageLoader = () => {
    return (
      <div className={pageLoader}>
        <Loader />
      </div>
    )
}

export default PageLoader
