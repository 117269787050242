import {ScaleTelekomHeader, ScaleTelekomNavList} from "@telekom/scale-components-react"
import React, {useCallback} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {useNavigate} from "react-router-dom"

import RolesManagementHoC from "@/hocs/roles-management-hoc/roles-management-hoc"
import useIsMobile from "@/hooks/useIsMobile"
import {appActions} from "@/model/slices/app-slice"
import {TStore} from "@/store"

import {icon_routes,routes} from "../entities"
import {RoutePath} from "../providers/router-provider/router-config"
import {NavItem} from "./nav-item"

export const Header = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isMobile = useIsMobile()
    const auth = useSelector((state: TStore) => state.appReducer.auth)
    const token = localStorage.getItem('token')

    const onLogout = useCallback(async () => {
        await dispatch(appActions.logout())
        await navigate(RoutePath.login)
    }, [dispatch])

    const isAuth = auth !== null && token !== null

    const mainRoutes = isAuth ? routes : []
    const iconRoutes = icon_routes(isAuth, onLogout, isMobile)

    const NavItemWithRoles = (item: any): JSX.Element => {
        if (item.roles) {
            const ItemWithRoles = RolesManagementHoC({
                WrappedComponent: <NavItem item={item} key={item.href} />,
                requiredRoles: item.roles
            })
            return <ItemWithRoles />
        } else {
            return <NavItem item={item} key={item.href} />
        }
    }

    return (
      <ScaleTelekomHeader
        appName={'TSEye'}
        main-nav-aria-label={"Main navigation"}
        type={'slim'}
      >
        {!isMobile ? <ScaleTelekomNavList slot={"main-nav"} aria-label={"Main navigation"} hidden={false}>
          {mainRoutes.map((item) => {
                  return <NavItemWithRoles {...item} key={item.href} />
              })}
        </ScaleTelekomNavList> : null}
        <ScaleTelekomNavList slot={"functions"} aria-label={"Icon navigation"}>
          {iconRoutes.map((item) => {
                    return <NavItemWithRoles {...item} key={item.href} />
                })}
        </ScaleTelekomNavList>
      </ScaleTelekomHeader>
    )
}
