import React from "react"

import RolesManagementHoC from "@/hocs/roles-management-hoc/roles-management-hoc"
import {Role} from "@/hocs/roles-management-hoc/types"
import {AllSetupsPage, CameraPage, MonitoringPage, ProfilePage, SetupPage, UserManagementPage} from "@/pages"

const AllSetupsPageWithRoles = RolesManagementHoC({
    WrappedComponent: <AllSetupsPage />,
    requiredRoles: [Role.ADMIN, Role.SUPER_USER, Role.CUSTOMER, Role.DEVELOPER, Role.VIEWER]
})

const SetupPageWithRoles = RolesManagementHoC({
    WrappedComponent: <SetupPage />,
    requiredRoles: [Role.ADMIN, Role.SUPER_USER, Role.CUSTOMER, Role.DEVELOPER, Role.VIEWER]
})

const CameraPageWithRoles = RolesManagementHoC({
    WrappedComponent: <CameraPage />,
    requiredRoles: [Role.ADMIN, Role.SUPER_USER, Role.CUSTOMER, Role.DEVELOPER, Role.VIEWER]
})

const MonitoringPageWithRoles = RolesManagementHoC({
    WrappedComponent: <MonitoringPage />,
    requiredRoles: [Role.ADMIN, Role.SUPER_USER, Role.CUSTOMER, Role.DEVELOPER, Role.VIEWER]
})

const UserManagementPageWithRoles = RolesManagementHoC({
    WrappedComponent: <UserManagementPage />,
    requiredRoles: [Role.ADMIN, Role.SUPER_USER]
})

const ProfilePageWithRoles = RolesManagementHoC({
    WrappedComponent: <ProfilePage />,
    requiredRoles: [Role.ADMIN, Role.SUPER_USER, Role.CUSTOMER, Role.DEVELOPER, Role.VIEWER]
})

export {
    AllSetupsPageWithRoles, CameraPageWithRoles,
    MonitoringPageWithRoles, ProfilePageWithRoles,
    SetupPageWithRoles, UserManagementPageWithRoles
}
