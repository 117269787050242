import React from 'react'

import Label from './stepper-step-label'
import Number from './stepper-step-number'

type Props = {
  name: string
  number: number
  isActive: boolean,
  filled: boolean,
}

const Step = ({name, number, isActive, filled}: Props) => {
  return <div className={'d-flex'}>
    <Number number={number} isActive={isActive || filled} />
    <Label name={name} number={number} isActive={isActive} />
  </div>
}

export default Step
