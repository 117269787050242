import React, {ReactNode, useEffect, useState} from "react"
import {DragDropContext, Draggable, Droppable, DropResult} from "react-beautiful-dnd"

import styles from "./style.module.scss"

const {
    'custom-grid__wrapper': grid_wrapper,
    'custom-grid__columns': grid_columns,
    'custom-grid__droppable': grid_droppable,
    'custom-grid__column': grid_column,
    'custom-grid__card': grid_card,
    'custom-grid__title': grid_title,
} = styles

interface DataItem {
    id: string,
    title: string;
    content: ReactNode;
}

interface Props {
    data: DataItem[];
    cardTemplate?: (item: any) => JSX.Element
}

interface Column {
    items: DataItem[];
}


const CustomGrid: React.FC<Props> = ({data, cardTemplate}) => {
    const middleIndex = Math.ceil(data.length / 2)
    const firstItems = data.slice(0, middleIndex)
    const secondItems = data.slice(middleIndex)
    const [columns, setColumns] = useState<Record<string, Column>>({
        first: {items: firstItems},
        second: {items: secondItems}
    })

    useEffect(() => {
        setColumns({
            first: {items: firstItems},
            second: {items: secondItems}
        })
    }, [data])

    const onDragEnd = (
        result: DropResult,
        columns: Record<string, Column>,
        setColumns: React.Dispatch<React.SetStateAction<Record<string, Column>>>
    ) => {
        if (!result.destination) return
        const {source, destination} = result

        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = columns[source.droppableId]
            const destColumn = columns[destination.droppableId]
            const sourceItems = [...sourceColumn.items]
            const destItems = [...destColumn.items]
            const [removed] = sourceItems.splice(source.index, 1)
            destItems.splice(destination.index, 0, removed)
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems
                },
                [destination.droppableId]: {
                    ...destColumn,
                    items: destItems
                }
            })
        } else {
            const column = columns[source.droppableId]
            const copiedItems = [...column.items]
            const [removed] = copiedItems.splice(source.index, 1)
            copiedItems.splice(destination.index, 0, removed)
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems
                }
            })
        }
    }

    if (data.length === 1) {
        return (
          <div className={grid_wrapper}>
            {!cardTemplate && (
            <div className={grid_card}>
              <span className={grid_title}>{data[0].title && data[0].title}</span>
              <span>{data[0].content}</span>
            </div>
                )}
            <div className={grid_card}>
              {cardTemplate && cardTemplate(data[0])}
            </div>
          </div>
        )
    }

    return (
      <div>
        <div className={grid_wrapper}>
          <DragDropContext
            onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
          >
            {Object.entries(columns).map(([columnId, column]) => {
                return (
                  <div
                    className={grid_columns}
                    key={columnId}
                  >
                    <div style={{margin: 8, width: '100%'}}>
                      <Droppable droppableId={columnId} key={columnId}>
                        {(provided) => {
                            return (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className={grid_droppable}
                              >
                                {column.items.map((item, index) => {
                                    return (
                                      <Draggable
                                        key={item.id}
                                        draggableId={item.id}
                                        index={index}
                                      >
                                        {(provided, snapshot) => {
                                                return (
                                                  <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className={grid_column}
                                                    style={{
                                                        ...provided.draggableProps.style,
                                                        backgroundColor: snapshot.isDragging
                                                            ? "#f80583"
                                                            : "transparent",
                                                  }}
                                                  >
                                                    {!cardTemplate && (
                                                      <div className={grid_card}>
                                                        <span className={grid_title}>{item.title && item.title}</span>
                                                        <span>{item.content}</span>
                                                      </div>
                                                      )}
                                                    <div className={grid_card}>
                                                      {cardTemplate && cardTemplate(item)}
                                                    </div>
                                                  </div>
                                                )
                                            }}
                                      </Draggable>
                                    )
                                    })}
                                {provided.placeholder}
                              </div>
                            )
                                }}
                      </Droppable>
                    </div>
                  </div>
                )
                    })}
          </DragDropContext>
        </div>
      </div>
    )
}

export default CustomGrid
