import {ScaleTelekomNavItem} from "@telekom/scale-components-react"
import React from 'react'
import {useSelector} from "react-redux"
import {Link} from "react-router-dom"

import {TStore} from "@/store"

interface NavItemType {
    href: string,
    name: string,
    icon?: JSX.Element,
    authOnly?: boolean
}

interface NavItemProps {
    item: NavItemType
}

export const NavItem = ({item}: NavItemProps) => {
   const {href, name, icon} = item
   const auth = useSelector((state: TStore) => state.appReducer.auth)

    const currentLink = window.location.pathname.replace('/', '')
    const isActive = currentLink === href

    if (item.authOnly && auth === null) return null
    return (
      <ScaleTelekomNavItem
        key={href}
        style={isActive ? {color: '#e20074'} : {}}
        aria-label={name}
      >
        <Link
          to={href}
        >
          {icon ? icon : name}
        </Link>
      </ScaleTelekomNavItem>
    )
}
