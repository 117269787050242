import {useEffect,useState} from 'react'

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(
        window.matchMedia('(max-width: 912px)').matches
    )

    useEffect(() => {
        const handleResize = (event: MediaQueryListEvent) => {
            setIsMobile(event.matches)
        }

        const mediaQuery = window.matchMedia('(max-width: 912px)')
        mediaQuery.addEventListener('change', handleResize)

        return () => {
            mediaQuery.removeEventListener('change', handleResize)
        }
    }, [])

    return isMobile
}



export default useIsMobile
