import {
    ScaleIconActionLogout,
    ScaleIconDeviceComputer,
    ScaleIconTProductUsage,
    ScaleIconUserFileCommunities,
    ScaleIconUserFileLogout,
    ScaleIconUserFileUser
} from "@telekom/scale-components-react"
import React from "react"

import {Role} from "@/hocs/roles-management-hoc/types"

export const routes = [
    {
        name: 'Setups',
        href: 'setups',
        authOnly: true,
        roles: [Role.ADMIN, Role.SUPER_USER, Role.CUSTOMER, Role.DEVELOPER, Role.VIEWER]
    },{
        name: 'User Management',
        href: 'user-management',
        authOnly: true,
        roles: [Role.ADMIN, Role.SUPER_USER]
    },{
        name: 'Monitoring',
        href: 'monitoring',
        authOnly: true,
        roles: [Role.ADMIN, Role.SUPER_USER, Role.CUSTOMER, Role.DEVELOPER, Role.VIEWER]
    }
]

export const footer_routes = [
    {
        name: 'Impressum',
        href: 'impressum'
    },
    {
        name: 'Datenschutzhinweise',
        href: 'datenschutzhinweise'
    }
]

export const icon_routes = (authenticated: boolean, onLogout: () => void, isMobile: boolean) => {
    const mobileRoutes = [{
        name: 'Setups',
        icon: <ScaleIconDeviceComputer />,
        href: 'setups',
        authOnly: true,
        roles: [Role.ADMIN, Role.SUPER_USER, Role.CUSTOMER, Role.DEVELOPER, Role.VIEWER]
    }, {
        name: 'User Management',
        icon: <ScaleIconUserFileCommunities />,
        href: 'user-management',
        authOnly: true,
        roles: [Role.ADMIN, Role.SUPER_USER]
    }, {
        name: 'Monitoring',
        icon: <ScaleIconTProductUsage />,
        href: 'monitoring',
        authOnly: true,
        roles: [Role.ADMIN, Role.SUPER_USER, Role.CUSTOMER, Role.DEVELOPER, Role.VIEWER]
    }]
    const actions = authenticated
        ? [
            ...(isMobile ? mobileRoutes : []),
            {
                name: 'Profile',
                icon: <ScaleIconUserFileUser />,
                href: 'profile',
                authOnly: true,
                roles: [Role.ADMIN, Role.SUPER_USER, Role.CUSTOMER, Role.DEVELOPER, Role.VIEWER, Role.AUDITOR]
            },
            {
                name: 'Logout',
                icon: <ScaleIconActionLogout onClick={onLogout} />,
                href: '',
                authOnly: true
            }
        ]
        : [
            {
                name: 'Login',
                icon: <ScaleIconUserFileLogout />,
                href: 'login'
            }
        ]
    return [...actions]
}
