import React, {
    memo, Suspense, useCallback, useEffect,
} from 'react'
import {Route, Routes, useNavigate} from 'react-router-dom'

import {PageLoader} from "@/components"
import useLocalStorageListener from "@/hooks/useLocalStorageListener"

import {Breadcrumb, CookieModal} from "../../components"
import {RequireAuth} from './require-auth'
import {RootRoutesProps, routeConfig, RoutePath} from './router-config'

// eslint-disable-next-line react/display-name
export const RootRouter = memo(() => {
    const navigate = useNavigate()

    useLocalStorageListener('token', () => {
        localStorage.setItem('session-message', 'Your session is expired. Please, Login again.')
        navigate(RoutePath.login)
    })

    useEffect(() => {
        if (window.location.pathname === '/') {
            navigate(RoutePath.all_setups)
        }
    }, [navigate])

    const renderWithWrapper = useCallback((route: RootRoutesProps) => {
        const element = (
          <Suspense fallback={<PageLoader />}>
            <CookieModal />
            <Breadcrumb />
            <div className={'center'}>{route.element}</div>
          </Suspense>
        )
        return (
          <Route
            key={route.path}
            path={route.path}
            element={route.authOnly ? <RequireAuth>{element}</RequireAuth> : element}
          />
        )
    }, [])
    return (
      <Suspense fallback={<PageLoader />}>
        <Routes>
          {Object.values(routeConfig).map(renderWithWrapper)}
        </Routes>
      </Suspense>
    )
})
