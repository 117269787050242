export type RawUser = {
  "id": string,
  "createdTimestamp": number,
  "username": string,
  "enabled": boolean,
  "totp": boolean,
  "emailVerified": boolean,
  "firstName": string,
  "lastName": string,
  "email": string,
  "disableableCredentialTypes": unknown[],
  "requiredActions": unknown[],
  "notBefore": number,
  "access": {
    "manageGroupMembership": boolean,
    "view": boolean,
    "mapRoles": boolean,
    "impersonate": boolean,
    "manage": boolean
  }
}

class Auth {
  constructor (
    public username: string,
    public firstName?: string,
    public lastName?: string,
    public email?: string,
    public id?: string) {
  }
}

export default Auth
