import './root.scss'

import {Dispatch} from "@reduxjs/toolkit"
import {ScaleDivider} from "@telekom/scale-components-react"
import React, {Suspense, useEffect} from 'react'
import {Helmet} from "react-helmet"
import {useDispatch, useSelector} from "react-redux"
import {BrowserRouter} from 'react-router-dom'

import {fetchAllDevices, fetchAllSetups, fetchAllUseCases} from "@/model/services/setups-services"
import {appActions} from "@/model/slices/app-slice"
import {TStore} from "@/store"

import {Footer, Header} from "./components"
import {RootRouter} from "./providers/router-provider/root-router"

const ContentSecurityPolicy = "default-src 'self'; " +
    "connect-src 'self' blob: configuration-api-dev.aisf.t-systems.net " +
    "keycloak-api-dev.aisf.t-systems.net " +
    "snapshot-api-dev.aisf.t-systems.net " +
    "kube-api-dev.aisf.t-systems.net; " +
    "frame-src 'self' https://grafana-dev.aisf.t-systems.net/ https://keycloak-dev.aisf.t-systems.net/; " +
    "script-src 'self'; " +
    "img-src 'self' blob:; " +
    "style-src 'self' 'unsafe-inline';"

const initFetch = async (dispatch: Dispatch) => {
  await fetchAllSetups(dispatch)
  await fetchAllUseCases(dispatch)
  await fetchAllDevices(dispatch)
}

const Root = () => {
  const dispatch = useDispatch()
  const inited = useSelector((state: TStore) => state.appReducer._inited)

  useEffect(() => {
    dispatch(appActions.initAuthData())
    initFetch(dispatch)
  }, [])

  const cookieAuth = Boolean(localStorage.getItem('cookie_auth'))
  const token = Boolean(localStorage.getItem('token'))

  if (!cookieAuth) {
    dispatch(appActions.updateApp({cookieAuthModal: true}))
  }

  useEffect(() => {
    if (!token) {
      if (!cookieAuth) {
        dispatch(appActions.updateApp({cookieAuthModal: true}))
      }
    }
  }, [token, cookieAuth])

  return <>
    <Helmet>
      <meta
        httpEquiv={"Content-Security-Policy"}
        content={ContentSecurityPolicy}
      />
    </Helmet>
    <Suspense fallback={""}>
      <BrowserRouter>
        <Header />
        {inited && <RootRouter />}
        <ScaleDivider />
        <Footer />
      </BrowserRouter>
    </Suspense>
  </>
}

export default Root
