import React, {useEffect, useState} from 'react'

import {randomId} from '@/domain/utils'

import styles from './styles.module.scss'

const {
  'toggle__wrapper': wrapperStyle,
  'toggle__wrapper--selected': wrapperSelectedStyle,
  'toggle__wrapper--unselected': wrapperUnselectedStyle,
  'toggle__input': inputStyle,
  'toggle__label': labelStyle,
  'toggle__icon--selected': iconSelected,
  'toggle__icon--unselected': iconUnselected
} = styles

export type Props<T> = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value'> & {
  label?: string,
  checked?: boolean,
  value: T,
  icon?: React.FunctionComponent
}

const ToggleButton = <T,>(props: Props<T>) => {
  const radioRef = React.useRef<HTMLInputElement|null>(null)

  const {label, id, value, icon: Icon, ...rest} = props
  const [_id] = useState(id ?? 'toggle-' + randomId())
  const cls = [wrapperStyle]

  const textChecked = props.checked
      ? wrapperSelectedStyle
      : wrapperUnselectedStyle
  const iconChecked = props.checked
      ? iconSelected
      : iconUnselected

  cls.push(Icon ? iconChecked : textChecked)

  useEffect(() => {
    if (radioRef?.current) {
      radioRef.current!['internalValue'] = value
    }
  },[value])

  return <div className={cls.join(' ')}>
    <input
      ref={radioRef}
      className={inputStyle}
      id={_id}
      type={'radio'}
      value={String(value)}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onChange={() => {} }  // just for avoid waring in console
      {...rest}
    />
    <label
      className={labelStyle}
      htmlFor={_id}
    >
      {Icon ? <Icon /> : label}
    </label>
  </div>
}

export default ToggleButton
