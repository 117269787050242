import {Dispatch} from "@reduxjs/toolkit"

import {doRequest} from "@/domain/api"
import {post} from "@/domain/http"
import {NewSetup} from "@/domain/types/setup"

import RequestError from "../../../exceptions/request-error"
import {appActions} from "../../slices/app-slice"
import {fetchAllSetups} from "./fetch-all-setups"


export const fetchAddSetup = async (dispatch: Dispatch, NewSetup: NewSetup) : Promise<NewSetup|undefined> => {
    try {
        dispatch(appActions.setLoading(true))

        await doRequest<NewSetup>(
            post('setup', {}, NewSetup),
            (message, detail) =>
                new RequestError('create', `Cannot create setup`, detail),
        )

        await fetchAllSetups(dispatch)
        dispatch(appActions.setLoading(false))
    } catch (error) {
        dispatch(appActions.setError(error.message))
        dispatch(appActions.setLoading(false))

        console.log(error)
        return undefined
    }
}
