export function decodeBase64Image(base64String: string) {
    const binaryString = atob(base64String)
    const len = binaryString.length
    const bytes = new Uint8Array(len)

    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i)
    }

    const blob = new Blob([bytes], {type: 'image/png'})
    return URL.createObjectURL(blob)
}
