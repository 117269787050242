import {useEffect, useState} from 'react'

const useLocalStorageListener = (key: string, callback: () => void) => {
    const [message, setMessage] = useState<string>('')

    useEffect(() => {
        const checkLocalStorageKey = () => {
            if (!localStorage.getItem(key)) {
                callback()
                setMessage(`The specified key '${key}' has been removed from localStorage.`)
            }
        }

        const localStorageChannel = new BroadcastChannel('localStorageChannel')

        const handleLocalStorageChange = (event: StorageEvent) => {
            if (event.storageArea === localStorage) {
                checkLocalStorageKey()
            }
        }

        window.addEventListener('storage', handleLocalStorageChange)

        localStorageChannel.addEventListener!('message', (event) => {
            if (event.data === 'localStorageChanged') {
                checkLocalStorageKey()
            }
        })

        return () => {
            window.removeEventListener('storage', handleLocalStorageChange)
            localStorageChannel.close()
        }
    }, [key, callback])

    return {message}
}

export default useLocalStorageListener
