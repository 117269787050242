import React, {FormEvent} from 'react'

import styles from './styles.module.scss'
import ToggleButton from './toggle-button'

const {
  'toggle__group': toggleGroup
} = styles

const defaultComparator = (v1: unknown, v2: unknown) => {
  return v1 === v2
}

export type ToggleButtonValue<T> = {
  title: string,
  value: T,
  icon?: React.FunctionComponent,
}

type Props<T> = {
  values: ToggleButtonValue<T>[],
  value: T,
  onChange: (newValue: ToggleButtonValue<T>) => void
  comparator?: (v1: T, v2: T) => boolean
}


const ToggleButtonsGroup = <T,>(props: Props<T>) => {
  const {value, values, onChange, comparator = defaultComparator} = props
  return <form
    className={toggleGroup}
    onChange={(e:FormEvent) => {
      const textValue = ( e.target as HTMLInputElement )['internalValue']
      const toggleButtonValue = values.find( value =>
        value.value === textValue
      )

      if (toggleButtonValue) {
        onChange(toggleButtonValue)
      }
    }}
  >
    { values.map( ({value: _value, title, icon}) =>
      <ToggleButton<T>
        key={title}
        name={'type'}
        value={_value}
        label={title}
        checked={comparator(_value, value)}
        icon={icon!}
      />
    )}
  </form>
}

export default ToggleButtonsGroup
