import {ScaleButton, ScaleIconActionFullScreen, ScaleIconActionMinimizeScreen} from '@telekom/scale-components-react'
import React from 'react'

import styles from './styles.module.scss'

const {
  'expand-button' : expandButton
} = styles

type Props = {
  expanded: boolean
  onClick: () => void
}

const ExpandButton = ({onClick, expanded}:Props) => {
  return <ScaleButton
    iconOnly={true}
    onClick={onClick}
    variant={'secondary'}
    className={expandButton}
  >
    { expanded
      ? <ScaleIconActionMinimizeScreen />
      : <ScaleIconActionFullScreen />}
  </ScaleButton>
}

export default ExpandButton
