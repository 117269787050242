import {ScaleDivider} from "@telekom/scale-components-react"
import React, {ReactNode} from "react"

import styles from "./styles.module.scss"

const {
    'page-header': pageHeader,
    'page-header-left': pageHeaderLeft,
    'page-header-title': pageHeaderTitle
} = styles

type PageHeaderProps = {
    label: string,
    controls?: ReactNode
}

const PageHeader = ({label, controls}: PageHeaderProps) => {
    return (
      <div className={pageHeader}>
        <div className={pageHeaderLeft}>
          <h1 className={pageHeaderTitle}>{label}</h1>
          {controls}
        </div>
        <ScaleDivider />
      </div>
    )
}

export default PageHeader
