import React from 'react'

import Dot from '@/domain/types/dot'
import {CountingAreaRoi} from '@/domain/types/rois'
import {LineOptional} from '@/domain/types/rois/counting-line/counting-line.coordinates'
import CountingLineRoi from '@/domain/types/rois/counting-line/counting-line.roi'
import RestrictedAreaRoi from '@/domain/types/rois/restricted-area/restricted-area.roi'
import RestrictedAreaRoiEditable from '@/domain/types/rois/restricted-area/restricted-area.roi-editable'
import {RoIBase} from '@/domain/types/rois/roi'

export const Colors = {
  DOT_FILL: '#FFFFFF',
  DOT_STROKE: '#FFFFFF',
  LINE_FILL: "#FF00FF",
  LINE_STROKE: "#FF00FF"
}

const DOT_TOUCH_SIZE = 15

export class PaintContext {
  private readonly context: CanvasRenderingContext2D
  private readonly width: number
  private readonly height: number

  constructor(canvas: HTMLCanvasElement) {
    this.context = canvas.getContext('2d')!
    this.width = canvas.width
    this.height = canvas.height
  }

  convertRelative2Real( dots: (Dot | undefined)[], scale = 100 ): Dot[] {
    return dots
      .filter( point => !!point )
      .map( point => new Dot(
        Math.round( point!.x * this.width / scale ),
        Math.round( point!.y * this.height / scale )
      ) )
  }

  convertReal2Relative( dots: (Dot | undefined)[], scale = 100 ): Dot[] {
    return dots
      .filter( point => !!point )
      .map( dot => new Dot(
        scale * dot!.x / this.width,
        scale * dot!.y / this.height
      ))
  }

  drawDot (
    x: number, y: number,
    color = Colors.DOT_STROKE
  ) {
    if ( x >= 0 && y >= 0 ) {
      this.dot(x, y, 3, Colors.DOT_FILL, color )
    }
  }

  drawTo(
    x: number, y: number, lx: number, ly: number,
    color = Colors.LINE_STROKE
  ) {
    if ( x >= 0 && y >= 0 && lx >= 0 && ly >= 0 ) {
      this.line( 2, x, y, lx, ly, color, color )
    }
  }

  intersectedAreas(
    areas: RoIBase[],
    x: number, y: number
  ): RoIBase | undefined {
      return areas.find( area => {
        switch (area.type) {
          case 'RestrictedArea': {
            return this.findIntersection((area as RestrictedAreaRoi).coordinates, RestrictedAreaRoi.SCALE_FACTOR, x, y )
          }
          case 'CountingLine': {
            const {line, arrow} = (area as CountingLineRoi).coordinates
            if (line && line.dot1 && line.dot2) {
              return this.findIntersection([ line.dot1, line.dot2 ],
                      CountingLineRoi.SCALE_FACTOR, x, y ) ||
                  this.findIntersection([ arrow.dot1, arrow.dot2 ],
                      CountingLineRoi.SCALE_FACTOR, x, y )
            }
          }
          break
          case 'CountingArea': {
            const countingArea = (area as CountingAreaRoi)
            const insidePoints = countingArea.coordinates.inside
            const outsidePoints = countingArea.coordinates.outside
            return this.findIntersection(insidePoints, CountingAreaRoi.SCALE_FACTOR, x,y) ||
              this.findIntersection(outsidePoints, CountingAreaRoi.SCALE_FACTOR, x,y)
          }
        }
      } )
  }

  findIntersection( points: Dot[], scale: number, x: number, y: number ) {
    const p =  new Path2D()
    this.createPath( p, points, scale)
    if ( this.context.isPointInPath( p, x, y ) || this.context.isPointInStroke( p, x, y ) ) {
      return p
    }
  }

  findPointFromEvent(e: React.MouseEvent | React.TouchEvent, area: RoIBase) {
    const {left, top} = this.context.canvas.getBoundingClientRect()
    const [_x,_y] = this.getCoordinates(e)

    if (_x >= 0 && _y >= 0) {
      const x = _x - left
      const y = _y - top

      return this.findPoint(area, x, y)
    }
  }

  findPoint( roi: RoIBase,
                             x: number,
                             y: number,
   ): Dot | undefined {
    switch (roi.type) {
      case 'RestrictedArea': {
        return this.findDot( (roi as RestrictedAreaRoi).coordinates, RestrictedAreaRoi.SCALE_FACTOR, x, y)
      }
      case 'CountingArea': {
        const {outside, inside} = (roi as CountingAreaRoi).coordinates
        return (
          this.findDot( outside, CountingAreaRoi.SCALE_FACTOR, x, y) ||
          this.findDot( inside, CountingAreaRoi.SCALE_FACTOR, x, y )
        )
      }
      case 'CountingLine': {
        const {line, arrow} = (roi as CountingLineRoi).coordinates
        return (
          this.findDot( [ line.dot1, line.dot2 ], CountingLineRoi.SCALE_FACTOR, x, y ) ||
          this.findDot( [ arrow.dot1, arrow.dot2 ], CountingLineRoi.SCALE_FACTOR, x, y )
        )
      }
    }
  }

  findDot( points: Dot[], scale: number, x: number, y: number) {
    return points
      .filter( p => !!p )
      .find( dot => {
        const [ {x: __x, y: __y} ] = this.convertRelative2Real( [ dot ], scale )
        return Math.abs( x - __x ) <= DOT_TOUCH_SIZE && Math.abs( y - __y ) <= DOT_TOUCH_SIZE
      } )
  }


  findAreaOnMouse(e: React.MouseEvent | React.TouchEvent, areas: RoIBase[] ) {
    const {left, top} = this.context.canvas.getBoundingClientRect()
    const [_x,_y] = this.getCoordinates(e)
    if (_x >= 0 && _y> 0) {
      const x = _x - left
      const y = _y - top
      return this.intersectedAreas(areas, x,y)
    }
  }

  createPath(path: CanvasPath, points: Dot[], scale: number) {
    let maxX = 0
    let minX = 0
    let maxY = 0
    let minY = 0

    if (points) {
      points && points.find( ( dot, i ) => {
        if (dot && dot.x !== undefined && dot.y !== undefined) {
          const [ {x: _x, y: _y} ] = this.convertRelative2Real( [ dot ], scale )

          maxX = Math.max( _x, maxX )
          minX = minX > 0 ? Math.min( _x, minX ) : _x

          maxY = Math.max( _y, maxY )
          minY = minY > 0 ? Math.min( _y, minY ) : _y

          if ( i === 0 ) {
            path.moveTo( _x, _y )
          } else {
            path.lineTo( _x, _y )
          }
        }
      } )
    }

    path.closePath()

    return {
      maxX,
      minX,
      minY,
      maxY
    }
  }

  paintPolygon(points: Dot[], scale: number, label: string, color: string) {
    this.context.lineWidth = 2
    this.context.fillStyle = `${ color }20`
    this.context.strokeStyle = color

    const values = this.convertRelative2Real( points, scale )

    this.context.beginPath()

    const {maxX, minX, minY, maxY} = this.createPath(this.context, points, scale)
    this.context.fill()
    this.context.stroke()

    values.forEach( ( {x, y}: Dot ) => {
      this.drawDot(x, y, color )
    } )

    this.context.fillStyle = `#FFFFFF`

    const font = document.body.style.fontFamily || 'Arial'

    if (this.width > 400) {
      this.context.font = `1.1rem ${ font }`
    } else {
      const w = this.width * 1.1/400
      this.context.font = `${w}rem ${ font }`
    }

    if ( label ) {
      const {width} = this.context.measureText( label )
      const centerX = minX + (maxX - minX - width) / 2
      const centerY = minY + (maxY - minY) / 2
      this.context.fillText( label, centerX, centerY )
    }
  }

  paintEditPolygon(
    points: Dot[],
    scale: number,
    color: string,
  ) {
    this.context.beginPath()
    const dots = this.convertRelative2Real( points, scale )
    dots.forEach( ( {x, y}, i ) => {
      // start of painting
      if ( i === 0 ) {
        this.context.moveTo( x, y )
        // continue painting
      } else {
        const dot = dots[i - 1]
        this.drawTo(dot.x, dot.y, x, y, color )
      }
    } )
    dots.forEach( ( {x, y} ) => {
      this.drawDot( x, y, color )
    } )
  }

  paintLine (points: LineOptional, color: string, scale: number ) {
    const [ dot1, dot2 ] = this.convertRelative2Real( [ points.dot1, points.dot2 ], scale )
    this.context.beginPath()
    // draw line
    if ( dot1 && dot2 ) {
      this.drawTo( dot1.x, dot1.y, dot2.x, dot2.y, color )
    }
    if ( dot2 ) {
      this.drawDot( dot2.x, dot2.y, color )
    }
    if ( dot1 ) {
      this.drawDot( dot1.x, dot1.y, color )
    }
  }

  paintArrow(points: LineOptional, color: string, scale: number ) {
    const [ dot1, dot2 ] = this.convertRelative2Real( [ points.dot1, points.dot2 ], scale )
    this.context.beginPath()
    // draw line
    if ( dot1 && dot2 ) {
      this.drawTo( dot1.x, dot1.y, dot2.x, dot2.y, color )
    }
    if ( dot1 ) {
      this.drawDot( dot1.x, dot1.y, color )
    }
    if ( dot2 && dot1 ) {
      this.vectorArrow( dot2.x, dot2.y, dot1.x, dot1.y, Colors.DOT_FILL, color )
    }
  }

  /**
   * Draw Area
   * @param area
   * @param highlighted
   */
  paintArea( area: RoIBase, highlighted = false) {
    if ( !area.isValid() || area.getLength() === 0) {
      return
    }
    const color = highlighted ? '#FFFF00' : area.color || '#FF00FF'

    switch (area.type) {
      case 'RestrictedArea': {
        const restrictedArea = area as RestrictedAreaRoi
        if (restrictedArea instanceof RestrictedAreaRoiEditable && !restrictedArea.isFinished()) {
          this.paintEditPolygon((area as RestrictedAreaRoi).coordinates,
                           RestrictedAreaRoi.SCALE_FACTOR, color)
        } else {
          this.paintPolygon((area as RestrictedAreaRoi).coordinates,
                        RestrictedAreaRoi.SCALE_FACTOR, area.name, color)
        }
        break
      }
      case 'CountingLine': {
        const {line, arrow} = (area as CountingLineRoi).coordinates
        this.paintLine( line, color, CountingLineRoi.SCALE_FACTOR )
        this.paintArrow( arrow, color, CountingLineRoi.SCALE_FACTOR )
        break
      }
      case 'CountingArea' : {
        const countingArea = area as CountingAreaRoi
        this.paintPolygon(countingArea.coordinates.outside,
                          CountingAreaRoi.SCALE_FACTOR, countingArea.getOutsideLabel(), color)
        this.paintPolygon(countingArea.coordinates.inside,
                          CountingAreaRoi.SCALE_FACTOR, countingArea.getInsideLabel(), color)
      }
    }
  }

  /**
   * Draw set of areas
   * @param area
   */
  paintAreas(area: RoIBase[], highlightedAreaId?: number) {
    this.context.clearRect( 0, 0, this.width, this.height )

    area.forEach( area => {
      this.paintArea(area, area.id === highlightedAreaId)
    } )
  }


  /**
   * Draw single Dot
   * @param x
   * @param y
   * @param radius
   * @param fillColor
   * @param strokeStyle
   */
  dot( x: number, y: number, radius: number, fillColor: string, strokeStyle: string) {
    const context = this.context
    context.beginPath()
    context.fillStyle = strokeStyle
    context.arc( x, y, radius, 0, 2 * Math.PI )
    context.fill()
    context.closePath()

    context.beginPath()
    context.fillStyle = fillColor
    context.arc( x, y, radius - 2, 0, 2 * Math.PI )
    context.fill()
    context.closePath()
  }

  /**
   * Draw single line
   * @param lineSize
   * @param x
   * @param y
   * @param lx
   * @param ly
   * @param fillColor
   * @param strokeStyle
   */
  line( lineSize: number, x: number, y: number, lx: number, ly: number, fillColor: string, strokeStyle: string ) {
    const context = this.context
    context.beginPath()
    context.fillStyle = fillColor
    context.strokeStyle = strokeStyle
    context.lineWidth = lineSize
    context.moveTo( lx, ly )
    context.lineTo( x, y )
    context.closePath()
    context.stroke()
    context.save()
  }

  vectorArrow(x: number, y: number, lx: number, ly: number, fillColor: string   = Colors.DOT_FILL,
              strokeStyle: string = Colors.DOT_STROKE ) {
    const context = this.context
    const alpha = Math.atan2( ly - y, lx - x )
    const arrowAng = Math.PI / 12
    const size = 20
    const sin30left = Math.sin( alpha + arrowAng )
    const sin30right = Math.sin( alpha - arrowAng )

    const cos30left = Math.cos( alpha + arrowAng )
    const cos30right = Math.cos( alpha - arrowAng )

    const _x1 = x + cos30left * size
    const _x2 = x + cos30right * size
    const lyLeft = y + sin30left * size
    const lyRight = y + sin30right * size

    context.beginPath()
    context.fillStyle = fillColor
    context.strokeStyle = strokeStyle
    context.lineWidth = 4
    context.moveTo( x, y )
    context.lineTo( _x1, lyLeft )
    context.lineTo( _x2, lyRight )
    context.lineTo( x, y )
    context.closePath()
    context.stroke()
    context.fill()
    context.save()
  }

  getCoordinates(e: React.MouseEvent | React.TouchEvent) {
    let x, y
    if (e.type.indexOf('touch') >= 0) {
      const ev = e as React.TouchEvent

      if (ev.touches.length === 1) {
        const touch = ev.touches.item(0)
        x = touch.clientX
        y = touch.clientY
      } else {
        return []
      }
    } else {
      const ev = e as React.MouseEvent
      x = ev.clientX
      y = ev.clientY
    }
    return [x,y]
  }

  moveDot(e: React.MouseEvent | React.TouchEvent, area: RoIBase) {
    const {left, top} = this.context.canvas.getBoundingClientRect()
    const [_x,_y] = this.getCoordinates(e)
    if (_x >= 0 && _y> 0) {
      const x = _x - left
      const y = _y - top

      // apply new position of selected dot
      const [newDot] = this.convertReal2Relative(
            [{x,y}],
            area.scaleFactor())
      return newDot
    }
  }

  changeCursor(cursor: string) {
    this.context.canvas.style.cursor = cursor
  }

  getOppositeColor(color: string) {
    return '#' + (color.match(/[A-F,0-9]{1,2}/g) ?? [])
        .map(num =>
            (255 - parseInt(num, 16)).toString(16).padStart(2, '0')
        ).join('')
  }
}




