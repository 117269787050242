import React from "react"
import {RouteProps} from 'react-router-dom'

import {
    DatenschutzhinweisePage, ImpressumPage, LoginPage,
    NotFoundPage, RestorePasswordPage
} from '@/pages'
import {
    AllSetupsPageWithRoles,
    CameraPageWithRoles, MonitoringPageWithRoles, ProfilePageWithRoles,
    SetupPageWithRoles, UserManagementPageWithRoles
} from "@/root/providers/router-provider/routes-with-roles"

export type RootRoutesProps = RouteProps & {
    authOnly?: boolean
}

export enum RootRoutes {
    LOGIN = 'login',
    ALL_SETUPS = 'all_setups',
    SETUP = 'setup',
    CAMERA = 'camera',
    MONITORING = 'monitoring',
    USER_MANAGEMENT = 'user_management',
    PROFILE = 'profile',
    DATENSCHUTZHINWEISE = 'datenschutzhinweise',
    IMPRESSUM = 'impressum',
    RESTORE_PASSWORD = 'restore_password',
    NOT_FOUND = 'not_found'
}

export const RoutePath: Record<RootRoutes, string> = {
    [RootRoutes.LOGIN]: '/login',
    [RootRoutes.ALL_SETUPS]: '/setups',
    [RootRoutes.SETUP]: '/setups/',
    [RootRoutes.CAMERA]: '/setups/',
    [RootRoutes.MONITORING]: '/monitoring',
    [RootRoutes.USER_MANAGEMENT]: '/user-management',
    [RootRoutes.PROFILE]: '/profile',
    [RootRoutes.DATENSCHUTZHINWEISE]: '/datenschutzhinweise',
    [RootRoutes.IMPRESSUM]: '/impressum',
    [RootRoutes.RESTORE_PASSWORD]: '/restore-password',
    [RootRoutes.NOT_FOUND]: '*',
}

export const routeConfig: Record<RootRoutes, RootRoutesProps> = {
    [RootRoutes.LOGIN]: {
        path: RoutePath.login,
        element: <LoginPage />,
    },
    [RootRoutes.ALL_SETUPS]: {
        path: RoutePath.all_setups,
        element: <AllSetupsPageWithRoles />,
        authOnly: true,
    },
    [RootRoutes.SETUP]: {
        path: `${RoutePath.setup}:id`,
        element: <SetupPageWithRoles />,
        authOnly: true,
    },
    [RootRoutes.CAMERA]: {
        path: `${RoutePath.camera}:id/:id`,
        element: <CameraPageWithRoles />,
        authOnly: true,
    },
    [RootRoutes.MONITORING]: {
        path: RoutePath.monitoring,
        element: <MonitoringPageWithRoles />,
        authOnly: true,
    },
    [RootRoutes.USER_MANAGEMENT]: {
        path: RoutePath.user_management,
        element: <UserManagementPageWithRoles />,
        authOnly: true,
    },
    [RootRoutes.PROFILE]: {
        path: RoutePath.profile,
        element: <ProfilePageWithRoles />,
        authOnly: true,
    },
    [RootRoutes.DATENSCHUTZHINWEISE]: {
        path: RoutePath.datenschutzhinweise,
        element: <DatenschutzhinweisePage />,
    },
    [RootRoutes.IMPRESSUM]: {
        path: RoutePath.impressum,
        element: <ImpressumPage />,
    },
    [RootRoutes.RESTORE_PASSWORD]: {
        path: RoutePath.restore_password,
        element: <RestorePasswordPage />,
    },
    [RootRoutes.NOT_FOUND]: {
        path: RoutePath.not_found,
        element: <NotFoundPage />,
    },
}
