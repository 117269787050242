import {createSlice} from "@reduxjs/toolkit"

import {ExistingUser} from "../../../pages/user-management/types"

interface initialInterface {
    user: ExistingUser
    users: ExistingUser[]
    usersCount: number

    editModal: boolean,
    addModal: boolean
}

const initialState: initialInterface = {
    user: {
        id: '',
        username: ''
    },
    users: [],
    usersCount: 0,

    editModal: false,
    addModal: false
}

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        updateUsers: (state = initialState, action) => ({
            ...state,
            ...action.payload
        }),
        setUser: (state = initialState, action) => {
            state.user = action.payload
        },
        setUsers: (state = initialState, action) => {
            state.users = action.payload
        },
        setUsersCount: (state = initialState, action) => {
            state.usersCount = action.payload
        },
        setControlAddModal: (state = initialState, action) => {
            state.addModal = action.payload
        },
        setControlEditModal: (state = initialState, action) => {
            state.editModal = action.payload
        }
    }
})

export const {actions: usersActions} = usersSlice
export const usersReducer = usersSlice.reducer
