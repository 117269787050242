import React, {ReactNode} from 'react'

import styles from './stepper.module.scss'
import Step from './stepper-step'
import StepInterval from './stepper-step-interval'

type Props = {
  steps: string[]
  activeStep: number
}

const stepperStyle = styles.stepper

const Stepper = ({steps, activeStep}: Props) => {
  return <div className={stepperStyle}>
    {
      steps.map((step, i) => <Step
        key={step}
        name={step}
        number={i + 1}
        isActive={activeStep === i}
        filled={activeStep > i}
      />)
        .reduce<ReactNode>((prev, curr, i) =>
        prev === null ? curr : [prev, <StepInterval key={'interval-' + i} />, curr], null)
    }
  </div>
}

export default Stepper
