import {createSlice} from "@reduxjs/toolkit"

import {MonitoringCard} from "../../../pages/monitoring/types"

interface initialInterface {
  cards: MonitoringCard[],
}

const initialState = {
  cards: [],
} as unknown as initialInterface

const monitoringSlice = createSlice({
  name: 'monitoring',
  initialState,
  reducers: {
    updateMonitoring: (state = initialState, action) => ({
      ...state,
      ...action.payload
    }),
  }
})

export const {actions: monitoringActions} = monitoringSlice
export const monitoringReducer = monitoringSlice.reducer
