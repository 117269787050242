import React from 'react'

import styles from './stepper.module.scss'

type Props = {
  number: number
  isActive: boolean
}

const {
  stepper__number: normalStyle,
  'stepper__number--active': activeStyle
} = styles

const Step = ({number, isActive}: Props) => {
  const classes = `${normalStyle} ${isActive ? activeStyle : ''}`
  return <div className={classes}>
    {number}
  </div>
}

export default Step
