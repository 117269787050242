import {
  ErrorResponse, REACT_APP_PROTOCOL, REACT_KUBE_API_HOST,
  REACT_SNAPSHOT_API_HOST, ResponseBody
} from './http'

const PROTOCOL = process.env.REACT_APP_PROTOCOL || REACT_APP_PROTOCOL
export const KUBE_API = PROTOCOL + '://' + (process.env.REACT_KUBE_API_HOST ?? REACT_KUBE_API_HOST)
export const SNAPSHOT_API = PROTOCOL + '://' + (process.env.REACT_SNAPSHOT_API_HOST ?? REACT_SNAPSHOT_API_HOST)

/**
 * T type of response body
 * V return type in promise. Usually T type equals to V type.
 * Sometimes we need to convert result, for that we use successHandler where
 * We can convert T to V.
 * @param promise
 * @param errorHandler
 * @param successHandler
 */
export const doRequest = async <T, V = T>(promise: Promise<ResponseBody<T>>,
  errorHandler: (message: string, detail: unknown, status?: number) => Error,
  successHandler: (result: T) => V = t => t as unknown as V ) : Promise<V | undefined> => {
  const response = await promise
  if (response.status === 'ok') {
    if (typeof response.body !== 'undefined') {
      return successHandler(response.body)
    }
  } else if (response.status === 'error') {
    const {message = 'Error', detail = 'Error', statusCode} = response as ErrorResponse
    throw errorHandler(message, detail, statusCode)
  }
}
