import {Components} from '@telekom/scale-components/dist/types/components'
import {ScaleNotificationMessage} from '@telekom/scale-components-react'
import React, {ReactNode, useEffect, useRef, useState} from 'react'

import CustomModal from "../custom-modal"
import Snackbar from "../snackbar"

type PageData = {
  setError: (message: string) => void,
  setSuccess: (message: string) => void,
  setInformational: (message: string) => void,
  setWarning: (message: string) => void,
  setSnackbar: (message: ReactNode) => void,
  setSnackbarDuration: (message: number | undefined) => void,
  showConfirm: (message: string) => Promise<'ok'| 'cancel'>,
}


export const PageContext = React.createContext<PageData>({} as PageData)

type Props = {
  informational?: ReactNode
  success?: ReactNode
  warning?: ReactNode
  error?: ReactNode,
  snackbar?: ReactNode,
  snackbarDuration?: number,
  children: ReactNode
  className?: string,
  confirmation?: ReactNode
}

const PageContainer = ({
  className,
  children,
  error,
  warning,
  informational,
  success,
  snackbar,
  snackbarDuration,
  confirmation
}:Props) => {
  const [_error, setError] = useState(error)
  const [_success, setSuccess] = useState(success)
  const [_informational, setInformational] = useState(informational)
  const [_warning, setWarning] = useState(warning)
  const [_snackbar, setSnackbar] = useState(snackbar)
  const [_snackbarDuration, setSnackbarDuration] = useState(snackbarDuration)
  const [_confirmation, setConfirmation] = useState(confirmation)

  const ref = useRef<(result: 'ok'|'cancel') => void | undefined>()
  useEffect(() => {
    setError(error)
    setWarning(warning)
    setSuccess(success)
    setInformational(informational)
    setSnackbar(snackbar)
    setSnackbarDuration(snackbarDuration)
    setConfirmation(confirmation)
  },[error, success, informational, warning, snackbar, snackbarDuration, confirmation])

  const onCloseSnackbar = () => {
    setSnackbar('')
  }

  const showConfirm = (text:string): Promise<'ok'|'cancel'> => {
    setConfirmation(text)
    return new Promise<'ok'|'cancel'>((resolve) => {
      ref.current = (res:'ok'|'cancel') => {
        setConfirmation(undefined)
        ref.current = undefined
        resolve(res)
      }
    })
  }

  return <PageContext.Provider value={{
    setError, setSuccess, setInformational, setWarning, setSnackbar, setSnackbarDuration, showConfirm}}
  >
    <div>
      { _success &&
        <Info
          variant={'success'}
          onClose={() => setSuccess(undefined) }
        >
          {_success}
        </Info> }
      { _informational &&
        <Info
          variant={'informational'}
          onClose={() => setInformational(undefined) }
        >
          {_informational}
        </Info > }
      { _warning &&
        <Info
          variant={'warning'}
          onClose={() => setWarning(undefined) }
        >
          {_warning}
        </Info> }
      { _error &&
        <Info
          variant={'error'}
          onClose={() => setError(undefined) }
        >
          {_error}
        </Info> }
      { _confirmation &&
        <CustomModal
          isDisabledOverlay
          title={'Action needed'}
          onAction={ ref.current! }
          content={_confirmation}
        />}

      <Snackbar
        onClose={onCloseSnackbar}
        isShow={!!_snackbar}
        timeOutLength={_snackbarDuration !== undefined ? _snackbarDuration : 0}
      >
        {_snackbar}</Snackbar>
      <div className={className}>
        { children }
      </div>
    </div>
  </PageContext.Provider>
}

type InfoProps = Partial<Components.ScaleNotificationMessage> & {
  children: ReactNode,
  onClose?: () => void
}

const Info = ( {children, variant , onClose}: InfoProps) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    setShow(true)
  }, [children])

  const ref = useRef()
  // Scale Notification component doesn't allow us to listen close event.
  // The component has auto closing functionality. We need to clear state after closing.
  // For that we have to use MutationObserver.
  useEffect(() => {
    let observer: MutationObserver
    if (ref.current) {
      observer = new MutationObserver((mutations) => {
        const mutation = mutations.some( mutation =>
          mutation.attributeName === 'opened'
        )
        if (mutation && !(ref.current! as HTMLElement).hasAttribute('opened')) {
          setShow(false)
          onClose?.()
          observer.disconnect()
        }
      })
      observer.observe(ref.current!, {attributes: true})
    }
    return () => {
      observer?.disconnect()
    }
  }, [ref.current])

  return <ScaleNotificationMessage
    ref={ref as any}
    variant={ variant }
    opened={ show }
    dismissible={ true }
    autoHideDuration={ 5000 }
    autoHide={ true }
  >
    { children }
  </ScaleNotificationMessage>
}


export default PageContainer
