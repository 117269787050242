import React, {SyntheticEvent, useEffect, useRef, useState} from 'react'

import Dot from '@/domain/types/dot'
import {RoIBase} from '@/domain/types/rois/roi'
import RoiEditable from '@/domain/types/rois/roi-editable'

import {PaintContext} from './paint-context'
import styles from './styles.module.scss'

const {
  'zone-editor__holder': zoneEditorHolder,
  'zone-editor__canvas': zoneEditorCanvas,
  'zone-editor__canvas--enabled': zoneEditorCanvasEnabled
} = styles

type CanvasProps = {
  width: number;
  height: number;
  areas: RoIBase[];
  areaToHighlightId?: number | undefined;
  onAreaHovered?: (areaId?: number) => void;
  onAreaDoubleClick?: (areaId: number) => void;
  onUpdate?: () => void;
  onAreaClick?: (areaId?: number) => void;
  selectedArea?: RoIBase | undefined;
  'data-test-id'?: string;
  isDisabled?: boolean
};

type That = {
  dragStarted: boolean;
  dot: Dot | undefined;
  isDragging: boolean;
};

const Canvas = (props: CanvasProps) => {
  const {
    width,
    height,
    areaToHighlightId,
    areas,
    selectedArea,
    onAreaHovered,
    onUpdate,
    onAreaDoubleClick,
    'data-test-id': testId,
    isDisabled = false,
  } = props

  const [context, setContext] = useState<PaintContext | undefined>(undefined)
  const [highlightedAreaId, setHighlightedAreaId] = useState<number | undefined>(undefined)
  const canvasPaintRef = useRef<HTMLCanvasElement | null>(null)
  const that = useRef<That>({
    dragStarted: false,
    dot: undefined,
    isDragging: false
  })

  const getCurrentEditable = () => {
    if (selectedArea instanceof RoiEditable) {
      return selectedArea as RoiEditable
    }
    return undefined
  }

  useEffect(() => {
    setHighlightedAreaId(areaToHighlightId)
  }, [areaToHighlightId])

  useEffect(() => {
    if (canvasPaintRef.current) {
      const context = new PaintContext(canvasPaintRef.current!)
      setContext(context)
    }
  }, [width, height, areas])

  useEffect(() => {
    if (context) {
      context.paintAreas(areas, highlightedAreaId)
    }
  }, [context, highlightedAreaId, areas])

  const onMouseUp = () => {
    that.current.dragStarted = false
    that.current.dot = undefined
  }

  const onMouseDown = (e: React.MouseEvent | React.TouchEvent) => {
    if (context) {
      const area = getCurrentEditable()
      if (area) {
        that.current.dragStarted = true
        that.current.dot = context.findPointFromEvent(e, area)
      }
    }
  }

  const onParentClick = (e: React.MouseEvent) => {
    if (that.current.isDragging) {
      that.current.isDragging = false
      e.stopPropagation()
    }
  }

  const onCanvasClick = (e: React.MouseEvent) => {
    const ev = e.nativeEvent as MouseEvent
    const {clientX, clientY} = ev
    if (selectedArea) {
      if (ev.target) {
        addPoint(clientX, clientY, ev.target)
      }
    }
  }

  const onCanvasTouchEnd = (e: SyntheticEvent) => {
    if (!that.current.isDragging) {
      const ev = e.nativeEvent as TouchEvent
      const {changedTouches} = ev
      const touch = changedTouches.item(0)
      if (touch) {
        const clientX = touch.clientX
        const clientY = touch.clientY

        if (ev.target) {
          addPoint(clientX, clientY, ev.target as EventTarget)
        }
        that.current.isDragging = false
      }
    }
  }

  const addPoint = (clientX: number, clientY: number, target: EventTarget) => {
    const area = getCurrentEditable()

    if (area && !area.isFinished()) {
      const {left, top} = (target as HTMLCanvasElement).getBoundingClientRect()
      const x = clientX - left
      const y = clientY - top

      if (context && area && x >= 0 && y >= 0) {
        const [point] = context.convertReal2Relative([{x, y}], area.scaleFactor())
        area.addDot(point)
        context.paintAreas(areas)
        onUpdate?.()
      }
    }
  }

  const onAreaDoubleTouch = () => {
    if (highlightedAreaId && onAreaDoubleClick) {
      onAreaDoubleClick(highlightedAreaId)
    }
  }

  const onMouseMove = (e: React.MouseEvent | React.TouchEvent) => {
    if (context) {
      const area = getCurrentEditable()
      if (area) {
        if (that.current.dragStarted) {
          that.current.isDragging = true
          const {dot} = that.current
          const newDot = context.moveDot(e, area)
          if (newDot && dot) {
            // apply new position of selected dot
            newDot.id = dot.id
            area.replaceDot(newDot)
            context.paintAreas(areas)
          }
        }
        const dot = context.findPointFromEvent(e, area)
        if (dot) {
          context.changeCursor('move')
        } else if (highlightedAreaId) {
          context.changeCursor('pointer')
        } else {
          context.changeCursor('')
        }
      } else {
        // find nearest area
        const areaUnder = context.findAreaOnMouse(e, areas)
        if (areaUnder?.id !== highlightedAreaId) {
          setHighlightedAreaId(areaUnder?.id)
          onAreaHovered?.(areaUnder?.id)
        }
      }
    }
  }

  const pListeners: Record<string, unknown> = {}
  const canvasListeners: Record<string, unknown> = {
    onMouseMove,
    onTouchMove: onMouseMove,
    onDoubleClick: onAreaDoubleTouch
  }

  if (selectedArea instanceof RoiEditable) {
    pListeners.onMouseDown = onMouseDown
    pListeners.onTouchStart = onMouseDown
    pListeners.onMouseUp = onMouseUp
    pListeners.onTouchMove = onMouseMove
    pListeners.onClickCapture = onParentClick

    canvasListeners.onClick = onCanvasClick
    canvasListeners.onTouchEnd = onCanvasTouchEnd
  }

  const className = [
    getCurrentEditable() ? zoneEditorCanvasEnabled : '',
    zoneEditorCanvas
  ].join(' ')

  return (
    <p className={zoneEditorHolder} {...pListeners}>
      <canvas
        {...canvasListeners}
        width={width}
        height={height}
        data-test-id={testId}
        className={className}
        ref={canvasPaintRef}
        style={isDisabled ? {opacity: 0.5, pointerEvents: 'none'} : {}}
      />
    </p>
  )
}

export default Canvas
