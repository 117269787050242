import {ConfigProvider,Select} from "antd"
import React from 'react'
const {Option} = Select
import style from "./style.module.scss"

const {
    'custom-select__wrapper': wrapper,
    'custom-select__select': select
} = style

interface CustomSelectInterface {
    placeholder: string,
    multi?: boolean,
    options: {label: string, value: string}[],
    onChange: (value: any) => void | undefined,
    value: any,
    disabled?: boolean,
    style?:  React.CSSProperties
}

const CustomSelect = ({
  placeholder, multi, options, onChange, value = [], disabled, style
}: CustomSelectInterface) => {
    const handleChange = (selectedValue: any) => {
        if (onChange) {
            onChange(selectedValue)
        }
    }
    return (
      <ConfigProvider
        theme={{
          token: {
              colorPrimary: 'E20074FF'
          }
      }}
      >
        <div className={wrapper}>
          {multi
            ?   <Select
                placeholder={placeholder}
                mode={'multiple'}
                className={select}
                size={'large'}
                allowClear
                disabled={disabled!}
                value={value!}
                style={style!}
                onChange={handleChange}
            >
              {options && options.map((item) => {
                        return <Option value={item.value} label={item.label} key={item.value}>
                          {item.label}
                        </Option>
                    })}
            </Select>
            :   <Select
                placeholder={placeholder}
                allowClear
                className={select}
                size={'large'}
                disabled={disabled!}
                value={value!}
                style={style!}
                onChange={handleChange}
            >
              {options && options.map((item) => {
                        return <Option value={item.value} label={item.label} key={item.value}>
                          {item.label}
                        </Option>
                    })}
            </Select>}
        </div>
      </ConfigProvider>
    )
}

export default CustomSelect
