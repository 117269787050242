import {Role} from "@/hocs/roles-management-hoc/types"

import Auth, {RawUser} from './types/auth'

export const REACT_CONFIGURATION_API_HOST = 'configuration-api-dev.aisf.t-systems.net'
export const REACT_KEYCLOAK_API_HOST = 'keycloak-api-dev.aisf.t-systems.net'
export const REACT_KUBE_API_HOST = 'kube-api-dev.aisf.t-systems.net'
export const REACT_SNAPSHOT_API_HOST = 'snapshot-api-dev.aisf.t-systems.net'
export const REACT_APP_PROTOCOL = 'https'

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE'
type Params = Record<string, string>

export type ErrorResponse = {
  status: 'error'
  message: string,
  detail: unknown,
  statusCode?: number
}

export type UnauthorizedResponse = {
  status: 'unauthorized',
  message?: string
}

export type ForbiddenResponse = {
  status: 'forbidden'
  message?: string
}

export type OKResponse<T = unknown> = {
  status: 'ok'
  body?: T
}

export type LoginResponseBody = {
  access_token: string,
  refresh_token: string
  realm_access: {
    roles: Role[]
  }
}

export type ResponseBody<T = unknown> =
  OKResponse<T> |
  ForbiddenResponse |
  UnauthorizedResponse |
  ErrorResponse

type BodyType = undefined | Omit<unknown, ''> | BodyInit

type RequestFunction<T> = (url:string, params?:Params, body?:BodyType) =>
  Promise<ResponseBody<T>> // eslint-disable-line

type ArgsBase<T> = [Method, ...Parameters<RequestFunction<T>>];

const PROTOCOL = process.env.REACT_APP_PROTOCOL || REACT_APP_PROTOCOL
const BASE = PROTOCOL + '://' + (process.env.REACT_CONFIGURATION_API_HOST ?? REACT_CONFIGURATION_API_HOST)
export const AUTH_URL = PROTOCOL + '://' + (process.env.REACT_KEYCLOAK_API_HOST ?? REACT_KEYCLOAK_API_HOST)

const getToken = () => {
  return localStorage.getItem('token') || ''
}
const getRefreshToken = () => {
  return localStorage.getItem('refreshToken') ?? ''
}

const setTokens = (token: string, refreshToken: string, ui_roles?: string[]) => {
  const lsRoles: string = JSON.stringify(ui_roles)
  localStorage.setItem('token', token)
  localStorage.setItem('refreshToken', refreshToken)
  localStorage.setItem('roles', lsRoles)
}

const clearToken = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('roles')
}

export const clearAndLogout = () => {
  clearToken()
  window.dispatchEvent(new Event('logout'))
}
// @ts-ignore
window.clearAndLogout = clearAndLogout

const isBodyInit = (body: BodyType) =>
  [Blob, ArrayBuffer, FormData, URLSearchParams, ReadableStream].some( T => body instanceof T)

const stringify = (body: BodyType): BodyInit => {
  if (body && !isBodyInit(body) && typeof body !== 'string') {
    return JSON.stringify(body)
  } else {
    return body as BodyInit
  }
}

const isURL = (url: string) => {
  try {
    new URL(url)
    return true
  } catch(e) {
    return false
  }
}

const request = async <T>(...args:ArgsBase<T>): Promise<ResponseBody<T>> => {
  try {
    const [method, url, params = {}, _body] = args

    const token = getToken()
    if (token) {
      const parameters = new URLSearchParams(params).toString()
      const body = stringify(_body)
      const headers = {
        'Content-Type': 'application/json',
        'token': token
      }

      const base = isURL(url) ? url : `${BASE}/${url}`

      const response = await fetch(`${base}${parameters ? '?' + parameters : ''}`, {
        method,
        headers,
        body
      })

      const status = response?.status
      switch (status) {
        case 401:
          return requestRefreshToken(() => request(...args))
        case 403:
          return {status: 'forbidden'}
        default: {
          if (status >= 200 && status < 300) {
            const body = await response.json() || undefined
            return {
              status: 'ok',
              body: body as T
            }
          } else {
            let body: Record<string, unknown> = {}
            const textResponse = response.clone()
            try {
              body = await response.json()
            } catch(e) {
              body.detail = await textResponse.text()
            }
            return {
              status: 'error',
              message: response.statusText,
              statusCode: status,
              detail: body.detail
            }
          }
        }
      }
    } else {
      clearAndLogout()
      return {
        status: 'unauthorized',
        message: 'No Valid taken'
      }
    }
  } catch (e) {
    return {
      status: 'error',
      message: e.message,
      detail: e
    }
  }
}

const login = async (username: string, pwd: string): Promise<ResponseBody> => {
  try {
    const resp = await fetch(`${AUTH_URL}/auth`, {
      method: 'POST',
      body: JSON.stringify({username, pwd}),
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
      }
    })
    if (resp.status === 200) {
      const {access_token, refresh_token, token_info} = await resp.json()
      const {roles} = token_info.realm_access
      const ui_roles: Role[] = roles.filter((role: Role) => Object.values(Role).includes(role)) as Role[]
      setTokens(access_token, refresh_token, ui_roles)
      return {status: 'ok'}
    } else if (resp.status === 401) {
      return {
        status: 'unauthorized'
      }
    } else {
      const body = await resp.json()
      return {
        status: 'error',
        message: resp.statusText,
        detail: body.detail
      }
    }
  } catch (e) {
    return {
      status: 'error',
      message: String('Invalid Username and Password'),
      detail: e
    }
  }
}

const requestRefreshToken = async (callback: Function) => {
  const _refreshToken = getRefreshToken()
  if (_refreshToken) {
    const tokens = await refreshToken(_refreshToken)
    if (tokens) {
      const {roles} = tokens.realm_access
      const ui_roles: Role[] = roles.filter((role: Role) => Object.values(Role).includes(role)) as Role[]
      setTokens(tokens.access_token, tokens.refresh_token, ui_roles)
      return callback()
    }
  }
  clearAndLogout()
  return {status: 'unauthorized'}
}

const refreshToken = async (refreshToken: string): Promise<LoginResponseBody | null> => {
  try {
    const resp = await fetch(`${AUTH_URL}/refresh`, {
      method: 'POST',
      body: JSON.stringify({refresh_token: refreshToken}),
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
      }
    })
    if (resp.status === 200) {
      return resp.json()
    }
    // eslint-disable-next-line no-empty
  } finally {  }
  return null
}

export const getUser = async (): Promise<ResponseBody<RawUser>> => {
  try {
    const token = getToken()
    if (token) {
      const resp = await fetch(`${AUTH_URL}/user`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'token': token
        }
      })
      switch (resp.status) {
        case 200: {
          const user = await resp.json()
          const auth = new Auth(
              user.username,
              user.firstName,
              user.lastName,
              user.email,
              user.id,
          )
          localStorage.setItem('user', JSON.stringify(auth))
          return {
            status: 'ok',
            body: <RawUser>user
          }
        }
        case 401:
          return requestRefreshToken( getUser )
        default: {
          const body = await resp.json()
          return {
            status: 'error',
            message: resp.statusText,
            detail: body.detail
          }
        }
      }
    } else {
      return {
        status: 'unauthorized',
        message: 'No Valid taken'
      }
    }
  } catch (e) {
    clearToken()
    return {
      status: 'error',
      message: String(e),
      detail: e
    }
  }
}

const isAuthorized = () => {
  const token = getToken()
  return token?.length > 0
}

// type Args<T> = Parameters<RequestFunction<T>>[]
type Args = [url: string, params?: Params | undefined, body?: BodyType];
const get = <T>(...args: Args) => request<T>('GET', ...args)
const put = <T>(...args: Args) => request<T>('PUT', ...args)
const post = <T>(...args: Args) => request<T>('POST', ...args)
const del = <T>(...args: Args) => request<T>('DELETE', ...args)

export {
  del,
  get,
  isAuthorized,
  login,
  post,
  put}
