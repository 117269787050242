import {Dispatch} from "@reduxjs/toolkit"

import {doRequest} from "@/domain/api"
import {put} from "@/domain/http"
import {NewSetup, Setup, toSetup} from "@/domain/types/setup"

import RequestError from "../../../exceptions/request-error"
import {appActions} from "../../slices/app-slice"
import {fetchCurrentSetup} from "./fetch-current-setup"


export const fetchUpdateSetup = async (
    dispatch: Dispatch, updateSetup: Partial<NewSetup>, id: number
) : Promise<Setup|undefined> => {
    try {
        dispatch(appActions.setLoading(true))

        await doRequest<Setup>(
            put<Setup>(`setup/${id}`, {}, toSetup(updateSetup)),
            (message, detail) =>
                new RequestError('update', `Cannot update setup`, detail),
        )

        await fetchCurrentSetup(dispatch, id)
        dispatch(appActions.setLoading(false))
    } catch (error) {
        dispatch(appActions.setError(error.message))
        dispatch(appActions.setLoading(false))

        console.log(error)
        return undefined
    }
}
