import React, {ReactNode, useEffect} from 'react'

import CustomIcon from "../custom-icon"
import styles from './style.module.scss'

const {
  'snackbar': snackbarStyle,
  'snackbar__children': snackbarChildren,
  'snackbar__close-icon': snackbarCloseIcon,
  'snackbar__show': snackbarShow,
  'snackbar__hide': snackbarHide,
} = styles

type Props = {
  children: ReactNode,
  onClose: () => void,
  isShow: boolean,
  timeOutLength?: number,
}

const defaultTimeout = 3000

const Snackbar = ({children, onClose, timeOutLength = defaultTimeout, isShow}: Props) => {
  useEffect(() => {
    if (typeof timeOutLength === 'number') {
      const timer = setTimeout(() => {
        onClose()
      }, timeOutLength)
      return () => {
        clearTimeout(timer)
      }
    }
  })

  const keyPressListener = (e: React.KeyboardEvent) => {
    if (e.code === 'Enter') {
      onClose()
    }
  }

  const classes = [snackbarStyle, `${!isShow ? snackbarHide : snackbarShow}`].join(' ')
  return <div className={classes} data-test-id={'snackbar'}>
    <div className={snackbarChildren}>{children}</div>
    <div
      className={snackbarCloseIcon}
      onClick={onClose}
      onKeyDown={keyPressListener}
      aria-label={"Close"}
      role={"button"}
      tabIndex={0}
    >
      <CustomIcon name={'close_cross'} fill={'#fff'} size={'16px'} />
    </div>
  </div>
}

export default Snackbar
