import {createSlice} from "@reduxjs/toolkit"

import Auth from "@/domain/types/auth"

import {ExistingUser} from "../../../pages/user-management/types"

interface initialInterface {
    cookieAuthModal: boolean
    cookieExist: boolean

    user: ExistingUser | null
    auth: Auth | null
    _inited: boolean

    loading: boolean
    error: string

    isExpandedCanvas: boolean
}

const initialState: initialInterface = {
    cookieAuthModal: false,
    cookieExist: false,

    user: null,
    auth: null,
    _inited: false,

    loading: false,
    error: '',

    isExpandedCanvas: false
}

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        updateApp: (state = initialState, action) => ({
            ...state,
            ...action.payload
        }),
        setLoading: (state = initialState, action) => {
            state.loading = action.payload
        },
        setError: (state = initialState, action) => {
            state.error = action.payload
        },
        setAuthData: (state = initialState, action) => {
            state.auth = action.payload
        },
        setUser: (state = initialState, action) => {
            state.user = action.payload
        },
        setExpandedCanvas: (state = initialState, action) => {
            state.isExpandedCanvas = action.payload
        },
        initAuthData: (state = initialState) => {
            const user = localStorage.getItem('user')
            if (user) {
                state.auth = JSON.parse(user)
            }
            state._inited = true
        },
        logout: (state = initialState) => {
            state.auth = null
            localStorage.removeItem('user')
            localStorage.removeItem('token')
            localStorage.removeItem('refreshToken')
            localStorage.removeItem('roles')
        },
    }
})

export const {actions: appActions} = appSlice
export const appReducer = appSlice.reducer
