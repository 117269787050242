import {getRandomColor} from '../../../utils'
import Dot from '../../dot'
import RoIType from '../../roi-type'
import RoiEditable from '../roi-editable'
import CountingAreaCoordinates from './counting-area.coordinates'
import CountingAreaRoi from './counting-area.roi'
import CountingAreaPartRoiEditable from './counting-area-part.roi-editable'

class CountingAreaRoiEditable extends RoiEditable<CountingAreaCoordinates> {
  outsideFinished = true
  insideFinished = true

  get type(): RoIType {
    return 'CountingArea'
  }

  static createNew() {
    const roi = new CountingAreaRoiEditable( {
                                               id: -1,
                                               color: getRandomColor(),
                                               coordinates: {
                                                 outside: [],
                                                 inside: []
                                               },
                                               name: '',
                                               type: 'CountingArea',
                                             } )
    roi.outsideFinished = false
    roi.insideFinished = false
    roi.finished = false
    return roi
  }

  public isInsideFinished() {
    return this.insideFinished
  }

  public setFinishInside( finished: boolean ) {
    return this.insideFinished = finished
  }

  public getInsideLabel() {
    return 'in:' + (this.name ?? '')
  }

  public getOutsideLabel() {
    return 'out:' + (this.name ?? '')
  }

  addDot( dot: Dot ): void {
    if ( this.insideFinished ) {
      this.coordinates.outside.push( dot )
    } else {
      this.coordinates.inside.push( dot )
    }
  }

  canAddNewDots(): boolean {
    return true
  }

  removeDot( dot: Dot ): void {
    if ( this.insideFinished ) {
      this.remove( this.coordinates.inside, dot )
    } else {
      this.remove( this.coordinates.inside, dot )
    }
  }

  replaceDot( dot: Dot ): void {
    this.replaceDotInList( this.coordinates.outside, dot )
    this.replaceDotInList( this.coordinates.inside, dot )
  }

  scaleFactor(): number {
    return CountingAreaRoi.SCALE_FACTOR
  }

  isFinished(): boolean {
    return this.insideFinished && this.outsideFinished
  }

  save(): CountingAreaRoi {
    return new CountingAreaRoi( this.id, this.name, this.color, this.getCoordinatesWithoutIds() )
  }

  getCoordinatesWithIds(coordinates:CountingAreaCoordinates): CountingAreaCoordinates {
    const {outside, inside} = coordinates
    return {
      outside: this.createIds( outside ),
      inside: this.createIds( inside )
    }
  }

  getCoordinatesWithoutIds(): CountingAreaCoordinates {
    const {outside, inside} = this.coordinates
    return {
      outside: outside.map( ( {x, y} ) => ({x, y}) ),
      inside: inside.map( ( {x, y} ) => ({x, y}) )
    }
  }

  canFinish(): boolean {
    const {outside, inside} = this.coordinates
    return outside.length > 3 && inside.length > 3
  }

  removeLastDot(): void {
    const {outside, inside} = this.coordinates
    if ( outside.length > 0 ) {
      this.coordinates.outside.splice( -1, 1 )
    } else if ( inside.length > 0 ) {
      this.coordinates.inside.splice( -1, 1 )
    }
  }

  clear(): void {
    this.coordinates = {
      outside: [],
      inside: []
    }
    this.insideFinished = false
    this.outsideFinished = false
  }

  getLength(): number {
    return CountingAreaRoi.prototype.getLength.call(this as CountingAreaRoiEditable)
  }

  createSingleAreas() {
    const outsideArea = new CountingAreaPartRoiEditable( this, true )
    const insideArea = new CountingAreaPartRoiEditable( this, false)

    if (!this.isFinished()) {
      outsideArea.unfreeze()
      insideArea.unfreeze()
    }

    return [ outsideArea, insideArea ]
  }
}

export default CountingAreaRoiEditable
