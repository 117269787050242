import {createSlice} from "@reduxjs/toolkit"

import {PodStatus} from "@/domain/service-status"

type PoDStatusType = {
    name: string,
    phase: PodStatus
}

interface initialInterface {
    podStatus: PoDStatusType
}

const initialState: initialInterface = {
    podStatus: {
        name: '',
        phase: 'Stopped'
    }
}

const podSlice = createSlice({
    name: 'pod',
    initialState,
    reducers: {
        updatePoD: (state = initialState, action) => ({
            ...state,
            ...action.payload
        }),
        setPoDStatus: (state = initialState, action) => {
            state.podStatus = action.payload
        },
    }
})

export const {actions: podActions} = podSlice
export const podReducer = podSlice.reducer
