import {createSlice} from "@reduxjs/toolkit"
import {ReactNode} from "react"

import {RoIBase} from "@/domain/types/rois/roi"

export enum CameraPreview {
    ACTIVE = 'ACTIVE',
    DISABLED = 'DISABLED'
}

interface initialInterface {
    highlightedArea?: RoIBase
    editingArea?: RoIBase
    information: ReactNode
    warning: ReactNode
    error: ReactNode
    success: ReactNode
    action: (() => void) | undefined
    actionText: string
    loading: boolean
    isOpenDialog: boolean
    isNewArea: boolean
    snackbar: string
    cameraPreviewState: CameraPreview
}

const initialState: initialInterface = {
    highlightedArea: undefined,
    editingArea: undefined,
    information: null,
    warning: null,
    error: null,
    success: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    action: () => {},
    loading: false,
    isOpenDialog: false,
    isNewArea: false,
    actionText: '',
    snackbar: '',
    cameraPreviewState: CameraPreview.ACTIVE
}

const cameraSlice = createSlice({
    name: 'camera',
    initialState,
    reducers: {
        setAreaHighlighted: (state = initialState, action) => {
            state.highlightedArea = action.payload
        },
        setEditArea: (state = initialState, action) => {
            state.editingArea = action.payload
            state.isNewArea = action.payload === undefined
        },
        setSuccess: (state = initialState, action) => {
            state.success = action.payload
        },
        setInformation: (state = initialState, action) => {
            state.information = action.payload
        },
        setWarning: (state = initialState, action) => {
            state.warning = action.payload
        },
        setError: (state = initialState, action) => {
            state.error = action.payload
        },
        setLoading: (state = initialState, action) => {
            state.loading = action.payload
        },
        setConfirmAction: (state = initialState, action) => {
            state.isOpenDialog = true
            state.action = action.payload.action
            state.actionText = action.payload.actionText
        },
        setClearAction: (state = initialState) => {
            state.isOpenDialog = false
            state.action = undefined
            state.actionText = ''
        },
        setSnackbar: (state = initialState, action) => {
            state.snackbar = action.payload
        },
        setClearSnackbar: (state = initialState) => {
            state.snackbar = ''
        },
        setCameraPreviewState: (state = initialState, action) => {
            state.snackbar = action.payload
        },
    }
})

export const {actions: cameraActions} = cameraSlice
export const cameraReducer = cameraSlice.reducer
