import sha256 from 'crypto-js/sha256'

class Visualizer {
  private strokewidth = 4
  private color = ''
  private bgColor = ''
  private context: CanvasRenderingContext2D = null as unknown as CanvasRenderingContext2D
  private maxRadius = 0
  private center = 0
  private canvasSize = 0
  private areaSize = 0
  private first = true

  constructor(private readonly canvas: HTMLCanvasElement) {
    const context = canvas.getContext('2d')
    if (context) {
      this.context = context
    } else {
      throw new Error('CanvasRenderingContext2D is null')
    }
  }

  init() {
    const {width, height} = this.canvas
    this.canvasSize = width
    this.areaSize = width
    this.maxRadius = width/2
    this.center = width/2
    this.context.clearRect(0,0,width, height)
  }

  getColor(n1:number, n2:number, n3:number) {
    const t = 255/10
    return '#' + [t*n1, t*n2, t*n3].reduce((all, item) => {
      return all + Number(Math.round(item)).toString(16).padStart(2, '0')
    }, '')
  }


  getBgColor(hex:string) {
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1)
    }
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
    }
    if (hex.length !== 6) {
      throw new Error('Invalid HEX color.')
    }
    const r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
        g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
        b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16)
    return '#' + this.padZero(r) + this.padZero(g) + this.padZero(b)
  }

  padZero(str:string) {
    const zeros = new Array(2).join('0')
    return (zeros + str).slice(-2)
  }

  paint(str: string) {
    this.init()
    const {words} = sha256(str)
    const sum = words.reduce( (all:number,value:number) =>  all + value, 0)
    const [w,color1, color2, color3, ...forms] = Array.from(String(Math.abs(sum))).reverse().map(Number)

    this.strokewidth = w || 10
    this.color = this.getColor(color1, color2, color3)
    this.bgColor = this.getBgColor(this.color)
    this.context.lineWidth = 1
    forms.forEach( form => {
      if (this.maxRadius > this.strokewidth) {
        if (form === 0) {
          this.paintCircle()
        } else if (form === 1) {
          this.paintSquare()
        } else {
          this.paintRectangle(form + 2)
        }
      }
    })
  }

  paintCircle() {
    this.paintCircleInt(this.maxRadius, this.color)
    this.maxRadius = this.maxRadius - this.strokewidth
    this.paintCircleInt(this.maxRadius, this.bgColor)
    this.first = false
  }

  paintRectangle(dotCount: number) {
    const angle = 2*Math.PI / dotCount
    this.paintRectangleInt(dotCount, this.maxRadius, angle, this.color)
    this.paintRectangleInt(dotCount, this.maxRadius - this.strokewidth, angle, this.bgColor)

    this.maxRadius = this.maxRadius*Math.cos(angle/2)
  }

  paintSquare() {
    const cos = Math.cos( Math.PI/4)
    const size = (this.maxRadius) * cos
    const diam = size * 2
    const correction = this.first ? 0: Math.floor(this.strokewidth)
    const offset = (this.canvasSize/2 - this.maxRadius) - (this.first ? 0 : this.strokewidth)
    const leftPoint = offset + this.maxRadius - size + correction

    this.paintSquareInt(leftPoint, this.color, diam, 0)
    this.paintSquareInt(leftPoint, this.bgColor, diam, this.strokewidth)

    this.maxRadius = size - this.strokewidth
    this.first = false
  }

  private paintCircleInt(radius: number, color: string) {
    this.context.fillStyle =  color
    this.context.beginPath()
    this.context.arc( this.center, this.center, radius, 0, 2 * Math.PI )
    this.context.closePath()
    this.context.fill()
  }

  paintStar() {
    // do next
  }

  private paintSquareInt(leftPoint: number, color: string, diam: number, correction = 0) {
    this.context.fillStyle = color
    this.context.beginPath()
    this.context.moveTo( leftPoint + correction, leftPoint + correction)
    this.context.lineTo( leftPoint + correction, leftPoint + diam - correction)
    this.context.lineTo( leftPoint + diam - correction, leftPoint + diam - correction )
    this.context.lineTo( leftPoint + diam - correction, leftPoint + correction)
    this.context.closePath()
    this.context.fill()
  }



  private paintRectangleInt(dotCount: number, radius: number, angle: number, color:string) {
    this.context.fillStyle = color
    this.context.beginPath()
    const pick = (this.canvasSize/2 - radius)

    this.context.moveTo(this.center,pick)

    for ( let i = 1; i < dotCount; i++ ) {
      const cos = Math.cos(Math.PI/2 - angle * i )
      const sin = Math.sin(Math.PI/2 - angle * i )

      this.context.lineTo( this.center - radius*cos , this.center - radius*sin )
    }

    this.context.closePath()
    this.context.fill()
  }
}

export default Visualizer
