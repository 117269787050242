import InvalidObjectFormat from '../exceptions/invalid-object-format'
import {RoiRaw} from './types/roi-raw'
import {CLZ} from './types/rois'
import Coordinates from './types/rois/coordinates'
import RoI from './types/rois/roi'

const ColorGenerator = function* (): Generator<string> {
  while(true) {
    yield '#FFA903'
    yield '#00A3FF'
    yield '#F90984'
    yield '#D709F9'
    yield '#79D9DB'
    yield '#D709F9'
    yield '#73C354'
  }
}

const colorGen = ColorGenerator()

export const getRandomColor = () => {
  return colorGen.next().value
}

export const createFromRaw = (areaRaw: RoiRaw): RoI<Coordinates> => {
  let coordinates
  const {roi_id, roi_name, roitype_id, roi_coordinates, roi_color} = areaRaw

  try {
    coordinates = roi_coordinates && JSON.parse(roi_coordinates)

    if (coordinates && coordinates.restricted_area) {
      coordinates = coordinates.restricted_area
    }

    const color = roi_color || colorGen.next().value

    const RoICls = CLZ[roitype_id]
    const obj = new RoICls(roi_id, roi_name, color, coordinates)
    obj.setValid(obj.testJSON(areaRaw))
    return obj
  } catch (e) {
    if (e instanceof SyntaxError) {
      throw new InvalidObjectFormat(`ROI with id '${roi_id}' and name '${roi_name}' has invalid format`)
    }
    throw e
  }
}

export const deepValue = (obj: object, path: string[] = []) => {
  return path.reduce((obj, propName) => obj?.[propName], obj)
}

export const random = (a:number,b:number) => {
  return a + (b-a)*Math.random()
}

export const randomId = () => {
  const a = random(0,10)
  const b = random(10,20)
  return random(a,b)
}
