import React from 'react'

interface BadgeProps {
    title: string;
    color: string;
}

const Badge: React.FC<BadgeProps> = ({title, color}) => {
    return (
      <div style={{backgroundColor: color, padding: '0.25rem', borderRadius: '0.25rem', color: '#fff'}}>
        {title}
      </div>
    )
}

export default Badge
