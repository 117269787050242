import {createSlice} from "@reduxjs/toolkit"

import {decodeBase64Image} from "@/utils/base64-decode"

export type SnapshotType = {
    filename: string,
    file_data: string
}

export interface SnapshotPrepareType extends SnapshotType {
    camera_ip: string
}

interface initialInterface {
    snapshot: SnapshotType
    decodedImg: string
    snapshot_loading: boolean
}

const initialState: initialInterface = {
    snapshot: {
        filename: '',
        file_data: ''
    },
    decodedImg: '',
    snapshot_loading: false
}

const snapshotSlice = createSlice({
    name: 'snapshot',
    initialState,
    reducers: {
        updateSnapshot: (state = initialState, action) => ({
            ...state,
            ...action.payload
        }),
        setSnapshot: (state = initialState, action) => {
            state.snapshot = action.payload
        },
        setDecodedSnapshot: (state = initialState, action) => {
            state.decodedImg = decodeBase64Image(action.payload)
        },
        setSnapshotLoading: (state = initialState, action) => {
            state.snapshot_loading = action.payload
        }
    }
})

export const {actions: snapshotActions} = snapshotSlice
export const snapshotReducer = snapshotSlice.reducer
