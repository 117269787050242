import React, {InputHTMLAttributes, useState} from 'react'

import styles from './styles.module.scss'

const {
  'color-input': colorInput,
  'color-input__input': inputClass,
  'color-input__color': colorClass,
  'color-input__label': labelClass,
  'color-input__label--disabled': labelDisabledClass,
  'color-input__text': textClass
} = styles


type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> & {
  value: string,
  title?: string,
  onChange?: (color:string) => void
}

const ColorPicker = ({id, value, className, onChange, title, disabled,...other}:Props) => {
  const [_id] = useState<string>(id || 'id_' + Date.now())
  const _labelClass = [
    disabled ? labelDisabledClass : '',
    labelClass
  ].join(' ')
  return <div className={[className, colorInput].join(' ')} >
    <label
      className={_labelClass}
      htmlFor={_id}
    >
      <span
        className={colorClass}
        style={{
          backgroundColor: value
        }}
      />
      <span className={textClass}>
        {title}
      </span>
    </label>

    <input
      id={_id}
      type={'color'}
      disabled={disabled}
      className={inputClass}
      onChange={ev => onChange?.(ev.target.value)}
      {...other}
    />
  </div>
}

export default ColorPicker
