import React from 'react'

import {Role} from "./types"

type HoCTypes<P extends object> = {
    WrappedComponent: React.ComponentType<P> | JSX.Element | React.ReactNode
    requiredRoles: Role[],
}

function isReactComponentType<P extends object>(component: React.ComponentType<P> | JSX.Element | React.ReactNode):
    component is React.ComponentType<P> {
    return typeof component === 'function'
}

// eslint-disable-next-line react/display-name
const RolesManagementHoC =<P extends object>({WrappedComponent, requiredRoles}: HoCTypes<P>) => {
    // eslint-disable-next-line react/display-name
    return (props: P) => {
        const stringRoles = localStorage.getItem('roles')
        const roles = (stringRoles !== null && stringRoles !== undefined && stringRoles !== 'undefined') ? JSON.parse(stringRoles) : []
        const hasRequiredRoles = requiredRoles.some((role) => roles.includes(role))

        return hasRequiredRoles ? (
            isReactComponentType(WrappedComponent) ? (
              <WrappedComponent {...props} />
            ) : (
                React.cloneElement(WrappedComponent as React.ReactElement)
            )
        ) : null
    }
}

export default RolesManagementHoC
