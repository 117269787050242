import React from 'react'

import styles from './stepper.module.scss'

const {
  'stepper__interval': intervalStyle,
  'stepper__interval-line': lineStyle
} = styles

const StepInterval = () => {
  return <div className={intervalStyle} >
    <div className={lineStyle} />
  </div>
}

export default StepInterval
