import React from 'react'
import {Link} from "react-router-dom"

import styles from "./styles.module.scss"

const {
    'breadcrumb': breadcrumb,
    'breadcrumb__item': breadcrumbItem,
    'active': active,
} = styles

type BreadcrumbItem = {
    name?: string;
    href?: string;
};

type BreadcrumbProps = {
    items: BreadcrumbItem[];
};

const CustomBreadcrumb: React.FC<BreadcrumbProps> = ({items}) => {
    return (
      <ol className={breadcrumb}>
        {items.map(({name, href}, index) => (
          <li className={`${breadcrumbItem} ${index === items.length - 1 ? `${active}` : ''}`} key={index}>
            {name !== undefined && href !== undefined && name !== "undefined" ? (
                            index === items.length - 1 ? (
                                name
                            ) : (
                              <>
                                <Link to={href}>{name}</Link>
                              </>
                            )
                        ) : (
                            'loading...'
                        )}
          </li>
                ))}
      </ol>

    )
}

export default CustomBreadcrumb
