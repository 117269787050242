import './calendar-style.scss'

import moment from "moment"
import React, {ChangeEvent,useEffect, useState} from "react"
import DatePicker from "react-datepicker"
import {CalendarContainer} from "react-datepicker"

import CustomIcon from "../custom-icon"
import styles from './style.module.scss'

const {
    'custom-date-picker': customDatePicker,
    'custom-date-picker__input-wrapper': inputWrapper,
    'custom-date-picker__input': input,
    'custom-date-picker__invalid-date': invalidDate
} = styles

type CustomContainerType = {
    className?: string;
    children?: React.ReactChild;
};

const CustomContainer = ({className, children}: CustomContainerType) => {
    return (
      <div>
        <CalendarContainer className={className}>
          <div>{children}</div>
        </CalendarContainer>
      </div>
    )
}

export type CustomDatePickerType = {
    label: string;
    selected: Date;
    startDate?: Date;
    endDate?: Date;
    handleChangeDate: (value: Date) => void;
    setIsDisabled: (value: boolean) => void;
};

const dateFormatMomentInitial = 'DD.MM.YYYY HH:mm'

const CustomDatePicker = ({
                              label,
                              selected,
                              startDate,
                              endDate,
                              handleChangeDate,
                              setIsDisabled
                          }: CustomDatePickerType) => {
    const [inputValue, setInputValue] = useState('')
    const [isWrong, setIsWrong] = useState(false)
    const [prepareDate, setPrepareDate] = useState('')

    useEffect(() => {
        const formatDate = moment(selected).format(dateFormatMomentInitial)
        if (formatDate !== prepareDate) {
            setInputValue(formatDate)
        }
        if (formatDate === '') {
            setInputValue('')
            setIsWrong(false)
        }
    }, [selected, prepareDate])

    return (
      <div>
        <div>{label}</div>
        <div className={`${inputWrapper} ${isWrong ? invalidDate : null}`}>
          <input
            className={input}
            value={inputValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const dateValue = e.currentTarget.value
                        const [day = '', month = '', year = ''] = dateValue.split('.')
                        const prepareValue = `${day.padStart(2, '0')}.${month.padStart(2, '0')}.${year}`
                        setPrepareDate(prepareValue)
                        setInputValue(dateValue)
                        const isValidDateTime = moment(prepareValue.trim(), dateFormatMomentInitial, true).isValid()
                        if (isValidDateTime) {
                            handleChangeDate(moment(prepareValue, dateFormatMomentInitial).toDate())
                            setIsWrong(false)
                            setIsDisabled(false)
                        } else {
                            setIsWrong(true)
                            setIsDisabled(true)
                        }
                    }}
          />
          <DatePicker
            className={customDatePicker}
            dateFormat={'dd.MM.yyyy HH:mm'}
            selected={selected}
            startDate={startDate}
            endDate={endDate}
            onChange={(value) => {
                        const date = moment(value, 'YYYY-MM-DD').toDate()
                        handleChangeDate(date)
                        setIsWrong(false)
                        setIsDisabled(false)
                    }}
            customInput={<div><CustomIcon name={'calendar'} /></div>}
            calendarContainer={CustomContainer}
            showTimeSelect
          />
        </div>
      </div>
    )
}

export default CustomDatePicker
