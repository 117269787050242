import {ScaleButton} from '@telekom/scale-components-react'
import React, {DetailedReactHTMLElement,useEffect, useState} from 'react'

import Stepper from './stepper'
import styles from './styles.module.scss'

const {'wizard': wizard, 'wizard__buttons': wizardButtons, 'wizard__children-container': wizardChildrenContainer} = styles

type WizardProps<T, R> = {
  initValue: T;
  children: React.ReactNode;
  onChanged?: (result: T, index: number) => void;
  onError?: (errors: string[]) => void;
  onFinish: (result: R) => void;
  onCancel?: () => void;
  backButtonTitle?: string;
  nextButtonTitle?: string;
  finishButtonTitle?: string;
  index?: number;
};

export type WizardStepProps<Y, R = Y> = {
  title: string;
  initValue?: Y;
  onChange?: (state: R) => void;
  onValidationFinished?: (errors: string[]) => void;
};

const clone = <T,>(
    element: DetailedReactHTMLElement<any, any> ,
    initValue: unknown,
    onChange: (state: T) => void,
    onValidationFinished: (errors: string[]) => void
): React.ReactElement<any, string | React.JSXElementConstructor<any>> =>
    React.cloneElement<any, any>(element, {onChange, initValue, onValidationFinished})


const init = <T, >(
    children: React.ReactNode,
    initValue: unknown,
    onChange: (state: T) => void,
    onValidationFinished: (errors: string[]) => void,
    index = 0
): [string[], React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined] => {
  const ret:
      [string[], React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined] = [[], undefined]
  if (children) {
    const array: React.ReactElement[] = React.Children.toArray(children) as React.ReactElement[]
    ret[0] = array.map(({props}) => props.title)
    ret[1] = clone(array[index] as DetailedReactHTMLElement<any, any>, initValue, onChange, onValidationFinished)
  }
  return ret
}

const Wizard = <T, R = T>(props: WizardProps<T, R>) => {
  const {
    children,
    onCancel,
    onChanged,
    index: _index
  } = props

  const [index, setIndex] = useState<number>(_index ?? 0)
  const [stepTitles, setStepTitles] = useState<string[]>([])
  const [currentChild, setCurrentChild] = useState<React.ReactNode>(undefined)
  const [state, setState] = useState<T>(props.initValue)
  const [errors, setErrors] = useState<string[]>([])

  useEffect(() => {
    onChanged?.(state, index)
  }, [index])

  const first = index === 0
  const last = index === React.Children.count(children) - 1

  const onMove = (newIndex: number) => {
    const child =
        clone(React.Children.toArray(children)[newIndex] as DetailedReactHTMLElement<any, any>,
            state, onChange, onValidationFinished)
    setIndex(newIndex)
    setCurrentChild(child)
  }

  const onChange = (newState: T) => setState(newState)
  const onNext = () => onMove(index + 1)
  const onBack = () => onMove(index - 1)

  const onFinish = () => {
    props.onFinish(state as unknown as R)
  }

  const onValidationFinished = (newErrors: string[]) => {
    setErrors(newErrors)
  }

  useEffect(() => {
    if (children) {
      const [titles, currentChild] = init(children, state, onChange, onValidationFinished, _index ?? index)
      setStepTitles(titles)
      setCurrentChild(currentChild)
      setIndex(_index ?? index)
      setState(props.initValue)
    }
  }, [children, _index])

  return (
    <div className={wizard}>
      <Stepper steps={stepTitles} activeStep={index} />
      <div className={wizardChildrenContainer}>{currentChild}</div>
      <div className={wizardButtons}>
        {!first && (
          <ScaleButton data-test-id={'button-back-step'} onClick={onBack} variant={'secondary'}>
            {'Back'}
          </ScaleButton>
          )}
        {!last && (
          <ScaleButton data-test-id={'button-next-step'} onClick={onNext} disabled={errors.length > 0}>
            {'Next'}
          </ScaleButton>
          )}
        {last && (
          <ScaleButton data-test-id={'button-finish-step'} onClick={onFinish} disabled={errors.length > 0}>
            {'Finish'}
          </ScaleButton>
          )}
        {
          <ScaleButton data-test-id={'button-cancel-step'} onClick={onCancel} variant={'secondary'}>
            {'Cancel'}
          </ScaleButton>
          }
      </div>
    </div>
  )
}

export default Wizard
