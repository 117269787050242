import React, {DetailedHTMLProps, HTMLAttributes, useEffect, useRef} from 'react'

import Visualizer from './visualizer'

type Props = {
  text: string,
  size?: number
} & DetailedHTMLProps<HTMLAttributes<HTMLCanvasElement>, HTMLCanvasElement>
function TextPicture({text, size = 300, ...props}:Props) {
  const ref = useRef(null)
  useEffect(() => {
    if (ref?.current && text.length > 0) {
      const v = new Visualizer(ref.current!)
      v.paint(text)
    }
  }, [text])

  return <canvas ref={ref} width={size} height={size} {...props }>
  </canvas>
}

export default TextPicture
