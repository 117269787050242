import React, {ErrorInfo, ReactNode, Suspense} from 'react'

import PageError from '../page-error'

interface ErrorBoundaryProps {
    children: ReactNode
}

interface ErrorBoundaryState {
    hasError: boolean
    error: Error | null
    errorInfo: ErrorInfo | null
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props)
        this.state = {hasError: false, error: null, errorInfo: null}
    }

    static getDerivedStateFromError(error: Error, errorInfo: ErrorInfo) {
        return {hasError: true, error, errorInfo}
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // eslint-disable-next-line react/no-access-state-in-setstate
        this.setState({...this.state, error, errorInfo})
        // eslint-disable-next-line no-console
        console.log(error, errorInfo)
    }

    render() {
        const {hasError, error, errorInfo} = this.state
        const {children} = this.props
        if (hasError) {
            return <Suspense fallback={""}><PageError error={error} errorInfo={errorInfo} /></Suspense>
        }

        return children
    }
}

export default ErrorBoundary
