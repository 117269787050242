import {expect} from 'chai'

import Dot from '../dot'
import {RoiRaw} from '../roi-raw'
import RoIType from '../roi-type'
import Coordinates from './coordinates'
import RestrictedAreaRoi from "./restricted-area/restricted-area.roi"
import RoiEditable from './roi-editable'

export interface RestrictedAreaRoiWithArray extends RestrictedAreaRoi {
  coordinates: Dot[]
}

enum RoITypeIDEnum {
  AREA = 'RestrictedArea',
  COUNTING_LINE = 'CountingLine',
  COUNTING_AREA = 'CountingArea'
}

export type IRoI<T = Coordinates> = {
  id: number,
  name: string,
  color: string,
  coordinates: T
  readonly type: RoIType
}

export type Constructor<T> =  {
  new (id: number,
    labels: string,
    color: string,
    coordinates: T): IRoI<T>
}

export const toRoIJSON = (roi: RestrictedAreaRoiWithArray) => {
  let type
  if (roi.type === 'RestrictedArea') {
    type = RoITypeIDEnum.AREA
  } else if (roi.type === 'CountingArea') {
    type = RoITypeIDEnum.COUNTING_AREA
  } else {
    type = RoITypeIDEnum.COUNTING_AREA
  }

  const coordinatesToArr = roi.coordinates as Array<any>

  const coordinates = {
    restricted_area: coordinatesToArr.map(item => ({x: item.x, y: item.y}))
  }

  const updatedRoi = {
    roi_color: roi.color,
    roi_id: roi.id,
    roi_name: roi.name,
    roitype_id: type,
    roi_coordinates: JSON.stringify(coordinates)
  }

  return [updatedRoi]
}


export const toNewRoIJSON = (roi: RoIBase, id: number) => {
  let type
  if (roi.type === 'RestrictedArea') {
    type = RoITypeIDEnum.AREA
  } else if (roi.type === 'CountingArea') {
    type = RoITypeIDEnum.COUNTING_AREA
  } else {
    type = RoITypeIDEnum.COUNTING_AREA
  }

  const coordinatesToArr = roi.coordinates as Array<any>

  const coordinates = {
      restricted_area: coordinatesToArr.map(item => ({x: item.x, y: item.y}))
  }
  return {
    roi_color: roi.color,
    roi_name: roi.name,
    roitype_id: type,
    roi_coordinates: JSON.stringify(coordinates),
    camera_id: id
  }
}

export const toRoIArr = (roi: RoIBase, id: number) => {
  const arr = []
  const rois = toNewRoIJSON(roi, id)
  arr.push(rois)
  return arr
}

abstract class RoIBase<T = Coordinates> implements IRoI<T> {
  private valid  = true
  constructor(
    public id: number,
    public name: string,
    public color: string,
    public coordinates: T
  ) {}

  get type():RoIType {
    throw Error('not implemented')
  }

  // set type(type: RoIType) {
  //   // ignore
  // }

  isValid() {
    return this.valid
  }

  setValid(valid: boolean) {
    this.valid = valid
  }

  getCoordinates(): T {
    return this.coordinates
  }

  abstract scaleFactor(): number

  abstract getLength(): number

  clone(overrides: Partial<RoI<T>>): IRoI<T> {
    const area = new (this.constructor as Constructor<T>)(
      this.id, this.name,
      this.color, this.coordinates)

    Object.assign(area, overrides)
    return area
  }

  toAreaJSON(): RoiRaw {
    return {
      roi_id: this.id,
      roi_name: this.name,
      roi_color: this.color,
      roitype_id: this.type,
      roi_coordinates: JSON.stringify( this.getCoordinates() )
    }
  }

  testJSON(obj: RoiRaw) {
    try {
      expect( typeof obj.roi_color ).to.be.oneOf(['string',  'undefined'])
      expect( obj.roi_name ).to.be.a('string')
      expect( obj.roitype_id ).to.be.a(this.type)
      expect( obj.roi_coordinates ).to.be.a('string')
    } catch (e) {
      return false
    }
    return true
  }

  public testDot(dot: Dot) {
    expect( dot ).to.be.an('object')
    expect( dot.x ).to.be.an('number')
    expect( dot.y ).to.be.an('number')
  }
}

abstract class RoI<T = Coordinates> extends RoIBase<T>{
  abstract createEditable(): RoiEditable
}

export default RoI
export {RoIBase}
