import {Dispatch} from "@reduxjs/toolkit"

import {doRequest} from "@/domain/api"
import {get} from "@/domain/http"
import {Setup} from "@/domain/types/setup"

import RequestError from "../../../exceptions/request-error"
import {appActions} from "../../slices/app-slice"
import {setupsActions} from "../../slices/setups-slice"

export const fetchAllSetups = async (dispatch: Dispatch) : Promise<Setup[]|undefined> => {
    try {
        dispatch(appActions.setLoading(true))

        const setups = await doRequest<Setup[]>(
            get<Setup[]>('setups'),
            (message, detail) =>
                new RequestError('read', `Cannot get all Setups`, detail),
        )

        dispatch(setupsActions.updateSetups({setups}))
        dispatch(appActions.setLoading(false))
    } catch (error) {
        dispatch(appActions.setError(error.message))
        dispatch(appActions.setLoading(false))

        console.log(error)
        return undefined
    }
}
