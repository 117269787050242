import {expect} from 'chai'

import {RoiRaw} from '../../roi-raw'
import RoIType from '../../roi-type'
import RoI from '../roi'
import CountingAreaCoordinates from './counting-area.coordinates'
import CountingAreaRoiEditable from './counting-area.roi-editable'

class CountingAreaRoi extends RoI<CountingAreaCoordinates> {
  static SCALE_FACTOR = 100
  static EDITABLE_CLASS = CountingAreaRoiEditable

  get type():RoIType {
    return 'CountingArea'
  }

  scaleFactor(): number {
    return CountingAreaRoi.SCALE_FACTOR
  }

  createEditable(): CountingAreaRoiEditable {
    return new CountingAreaRoiEditable(this)
  }

  getLength(): number {
    const {outside, inside} = this.coordinates
    return outside.length + inside.length
  }

  public getInsideLabel() {
    return 'in:' + (this.name ?? '')
  }

  public getOutsideLabel() {
    return 'out:' + (this.name ?? '')
  }

  testJSON(obj: RoiRaw) {
    try {
      super.testJSON(obj)
      const coordinates:CountingAreaCoordinates = JSON.parse(obj.roi_coordinates)
      expect( coordinates ).to.be.an('object')
      expect( coordinates.inside ).to.be.an('array')
      expect( coordinates.outside ).to.be.an('array')
      coordinates.inside.every( super.testDot )
      coordinates.outside.every( super.testDot )
    } catch (e) {
      return false
    }
    return true
  }
}

export default CountingAreaRoi
