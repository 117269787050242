import {expect} from 'chai'

import {RoiRaw} from '../../roi-raw'
import RoIType from '../../roi-type'
import RoI from '../roi'
import RestrictedAreaCoordinates from './restricted-area.coordinates'
import RestrictedAreaRoiEditable from './restricted-area.roi-editable'

class RestrictedAreaRoi extends RoI<RestrictedAreaCoordinates> {
  static SCALE_FACTOR = 100
  static EDITABLE_CLASS = RestrictedAreaRoiEditable

  get type():RoIType {
    return 'RestrictedArea'
  }

  scaleFactor(): number {
    return RestrictedAreaRoi.SCALE_FACTOR
  }

  createEditable(): RestrictedAreaRoiEditable {
    return new RestrictedAreaRoiEditable( this )
  }

  getLength(): number {
    return this.coordinates.length
  }

  testJSON(obj: RoiRaw) {
    try {
      let coordinates
      coordinates = obj.roi_coordinates && JSON.parse(obj.roi_coordinates)

      if (coordinates && coordinates.restricted_area) {
        coordinates = coordinates.restricted_area
      }
      super.testJSON(obj)
      expect( coordinates ).to.be.an('array')
      coordinates.every( super.testDot )
    } catch (e) {
      return false
    }
    return true
  }
}

export default RestrictedAreaRoi
