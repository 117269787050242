import {getRandomColor} from '../../../utils'
import Dot from '../../dot'
import RoIType from '../../roi-type'
import RoiEditable from '../roi-editable'
import CountingLineCoordinates, {CountingLineCoordinatesEditable} from './counting-line.coordinates'
import CountingLineRoi from './counting-line.roi'

class CountingLineRoiEditable extends RoiEditable<CountingLineCoordinatesEditable> {
  get type(): RoIType {
    return 'CountingLine'
  }

  static createNew() {
    return new CountingLineRoiEditable( {
                                          id: -1,
                                          color: getRandomColor(),
                                          coordinates: {
                                            line: {},
                                            arrow: {}
                                          },
                                          name: '',
                                          type: "CountingLine"
                                        } )
  }

  addDot( dot: Dot ): void {
    const {line: {dot1, dot2}, arrow} = this.coordinates as CountingLineCoordinates

    if ( !dot1 ) {
      this.coordinates.line.dot1 = dot
    } else if ( !dot2 ) {
      this.coordinates.line.dot2 = dot
    } else if ( !arrow.dot1 ) {
      this.coordinates.arrow.dot1 = dot
    } else if ( !arrow.dot2 ) {
      this.coordinates.arrow.dot2 = dot
    }
  }

  canAddNewDots(): boolean {
    const {line: {dot1, dot2}, arrow} = this.coordinates as CountingLineCoordinates
    return !(dot1 || dot2 || arrow.dot1 || arrow.dot2)
  }

  getCoordinatesWithIds(coordinates:CountingLineCoordinatesEditable): CountingLineCoordinatesEditable {
    const {line: {dot1, dot2}, arrow} = coordinates
    return {
      line: {
        dot1: dot1 ? new Dot( dot1.x, dot1.y, this.id + '-line-dot-1' ) : undefined,
        dot2: dot2 ? new Dot( dot2.x, dot2.y, this.id + '-line-dot-2' ) : undefined
      },
      arrow: {
        dot1: arrow.dot1 ? new Dot( arrow.dot1.x, arrow.dot1.y, this.id + '-arrow-dot-1' ) : undefined,
        dot2: arrow.dot2 ? new Dot( arrow.dot2.x, arrow.dot2.y, this.id + '-arrow-dot-2' ) : undefined
      }
    }
  }

  getCoordinatesWithoutIds(): CountingLineCoordinates {
    return this.coordinates as CountingLineCoordinates
  }

  isFinished(): boolean {
    return false
  }

  removeDot( {id}: Dot ): void {
    const {line: {dot1, dot2}, arrow} = this.coordinates
    if ( dot1?.id === id ) {
      delete this.coordinates.line.dot1
    }
    if ( dot2?.id === id ) {
      delete this.coordinates.line.dot2
    }
    if ( arrow.dot1?.id === id ) {
      delete this.coordinates.arrow.dot1
    }
    if ( arrow.dot2?.id === id ) {
      delete this.coordinates.arrow.dot2
    }
  }

  replaceDot( dot: Dot ): void {
    const {id} = dot
    const {line: {dot1, dot2}, arrow} = this.coordinates
    if ( dot1?.id === id ) {
      this.coordinates.line.dot1 = dot
    }
    if ( dot2?.id === id ) {
      this.coordinates.line.dot2 = dot
    }
    if ( arrow.dot1?.id === id ) {
      this.coordinates.arrow.dot1 = dot
    }
    if ( arrow.dot2?.id === id ) {
      this.coordinates.arrow.dot2 = dot
    }
  }

  save(): CountingLineRoi {
    return new CountingLineRoi( this.id, this.name, this.color, this.getCoordinatesWithoutIds() )
  }

  scaleFactor(): number {
    return CountingLineRoi.SCALE_FACTOR
  }

  canFinish(): boolean {
    const {line, arrow} = this.coordinates
    return !!(line.dot1 && line.dot2 && arrow.dot1 && arrow.dot2)
  }

  getLength(): number {
    return CountingLineRoi.prototype.getLength.call(CountingLineRoiEditable)
  }

  removeLastDot(): void {
    const {line, arrow} = this.coordinates

    if ( arrow.dot2 ) {
      delete this.coordinates.arrow.dot2
    } else if ( arrow.dot1 ) {
      delete this.coordinates.arrow.dot1
    } else if ( line.dot2 ) {
      delete this.coordinates.line.dot2
    } else if ( line.dot1 ) {
      delete this.coordinates.line.dot1
    }
  }

  clear(): void {
    this.coordinates = {
      line: {},
      arrow: {}
    }
    this.finished = false
  }
}

export default CountingLineRoiEditable
