import {appReducer} from "../slices/app-slice"
import {cameraReducer} from "../slices/camera-slice"
import {monitoringReducer} from "../slices/monitoring-slice"
import {podReducer} from "../slices/pod-slice"
import {setupsReducer} from "../slices/setups-slice"
import {snapshotReducer} from '../slices/snapshot-slice'
import {usersReducer} from "../slices/users-slice"

const reducer = {
  monitoringReducer,
  setupsReducer,
  appReducer,
  cameraReducer,
  usersReducer,
  podReducer,
  snapshotReducer
}

export default reducer
