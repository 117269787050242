import {Tooltip as TooltipComponent} from "antd"
import React from 'react'

type TooltipType = {
    children: React.ReactNode
    text: string
    placement?: 'top' | 'right' | 'bottom' | 'left'
}
const Tooltip = ({...props}: TooltipType) => {
    const {children, text, placement = 'left'} = props
    return (
      <TooltipComponent
        title={text}
        placement={placement}
      >
        <span {...props}>{children}</span>
      </TooltipComponent>
    )
}

export default Tooltip
