import {getRandomColor} from '../../../utils'
import Dot from '../../dot'
import RoIType from '../../roi-type'
import {RestrictedAreaCoordinates,RestrictedAreaRoi} from '../index'
import RoiEditable from '../roi-editable'

class RestrictedAreaRoiEditable extends RoiEditable<RestrictedAreaCoordinates> {
  get type(): RoIType {
    return 'RestrictedArea'
  }

  static createNew(): RestrictedAreaRoiEditable {
    const roi = new RestrictedAreaRoiEditable( {
      id: -1,
      color: getRandomColor(),
      coordinates: [],
      name: '',
      type: 'RestrictedArea'
    } )
    roi.finished = false
    return roi
  }

  addDot( dot: Dot ): void {
    this.coordinates.push( dot )
  }

  canAddNewDots(): boolean {
    return true
  }

  getCoordinatesWithIds(coordinates:RestrictedAreaCoordinates): RestrictedAreaCoordinates {
    let dots: Dot[]
    // eslint-disable-next-line prefer-const
    dots = this.createIds(coordinates)
    return dots
  }

  getCoordinatesWithoutIds(): RestrictedAreaCoordinates {
    return this.coordinates
  }

  isFinished(): boolean {
    return this.finished
  }

  removeDot( dot: Dot ): void {
    this.remove( this.coordinates, dot )
  }

  replaceDot( dot: Dot ): void {
    this.replaceDotInList( this.coordinates, dot )
  }

  save(): RestrictedAreaRoi {
    return new RestrictedAreaRoi( this.id, this.name, this.color, this.getCoordinatesWithoutIds() )
  }

  scaleFactor(): number {
    return RestrictedAreaRoi.SCALE_FACTOR
  }

  canFinish(): boolean {
    return this.coordinates.length > 2
  }

  getLength(): number {
    return RestrictedAreaRoi.prototype.getLength.call(this as RestrictedAreaRoiEditable)
  }

  removeLastDot(): void {
    this.coordinates.splice( -1, 1 )
  }

  clear(): void {
    this.coordinates.length = 0
    this.finished = false
  }
}

export default RestrictedAreaRoiEditable
