import React, {useEffect, useRef, useState} from "react"
import {useDispatch} from "react-redux"

import {RoIBase} from '@/domain/types/rois/roi'
import RoiEditable from '@/domain/types/rois/roi-editable'
import {randomId} from '@/domain/utils'
import {appActions} from "@/model/slices/app-slice"
import {CameraPreview} from "@/model/slices/camera-slice"

import Canvas from './canvas'
import ExpandButton from './expand-button'
import styles from './styles.module.scss'
import Video from './video'

type Props = {
  videoSrc: string
  areas: RoIBase[]
  currentArea?: RoiEditable
  areaToHighlight?: RoIBase | undefined
  onAreaHovered?: (area?: RoIBase) => void
  onAreaDoubleClick?: (area: RoIBase) => void
  onCurrentAreaChanged?: (area?: RoIBase) => void
  onUpdate?: () => void
  className?: string
  absolutePosition?: boolean
  expandable?: boolean
  cameraPreviewState?: CameraPreview
}

const {
  'zone-editor': zoneEditor,
  'zone-editor--expanded': zoneEditorExpanded,
  'zone-editor--absolute': zoneEditorAbsolute,
  'zone-editor--relative': zoneEditorRelative,
  'zone-editor__fit-container': zoneEditorFitContainer,
  'zone-editor__fit-container--broken': zoneEditorFitContainerBroken,
  'zone-editor__canvas-container': zoneEditorCanvasContainer,
  'zone-editor__expand-button': zoneEditorExpandButton
} = styles

const zoneEditorClass = (broken: boolean) => `${zoneEditorFitContainer}${ broken ? ' ' + zoneEditorFitContainerBroken : '' }`

const ZoneEditor = (props : Props) => {
  const {
    videoSrc,
    areaToHighlight,
    areas,
    currentArea,
    onAreaHovered,
    onUpdate,
    onAreaDoubleClick,
    expandable = true,
    absolutePosition = false,
    cameraPreviewState = CameraPreview.ACTIVE
  } = props

  const dispatch = useDispatch()
  const [expanded, setExpanded] = useState<boolean>(false)
  const [broken, setBroken] = useState<boolean>(false)
  const [load, setLoad] = useState<boolean>(true)
  const [update, setUpdate] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [{width, height}, setSizes] = useState<{ width: number, height: number }>({width: 0, height: 0})
  const videoRef = useRef<HTMLVideoElement|HTMLImageElement|null>(null)


  useEffect(() => {
    if (videoRef.current) {
      const current = videoRef.current
      const observer = new ResizeObserver( entries => {
        const [{contentRect}] = entries
        setSizes({
          width: Math.floor(contentRect.width),
          height: Math.floor(contentRect.height)
        })
      })

      observer.observe(current!)
      return () => {
        observer.unobserve(current!)
      }
    }
  }, [videoRef.current])

  useEffect( () => {
    setUpdate(String(randomId()))
  }, [currentArea, currentArea?.isFinished()])

  const className = [
    zoneEditor,
    expanded
      ? zoneEditorExpanded
      : absolutePosition
        ? zoneEditorAbsolute
        : zoneEditorRelative,
  ].join(' ')

  const errorHandler = () => {
    setLoad(false)
    setError('Error with retrieving metadata...')
    setBroken(true)
  }
  const loadHandler = () => setLoad(false)

  const _onAreaHovered = (id?: number) => {
    onAreaHovered?.(areas.find( area => area.id === id ))
  }

  const _onAreaDoubleClick = (id?: number) => {
    const area = areas.find( area => area.id === id )
    if (area) {
      onAreaDoubleClick?.(area)
    }
  }

  const isDisabled = CameraPreview.DISABLED === cameraPreviewState

  return (<div className={className}>
    {
      load &&
      <div className={zoneEditorFitContainer}>
        <h2>Retrieving metadata...</h2>
      </div>
    }
    {error && <div>Error with retrieving metadata...</div>}
    <div className={zoneEditorClass(broken)} style={{visibility: load ? 'hidden' : 'visible'}}>
      <Video
        ref={videoRef}
        src={videoSrc}
        errorHandler={errorHandler}
        loadHandler={loadHandler}
        isDisabled={isDisabled}
      />
      {/* eslint-enable */}
      <div
        data-testid={'canvas-container'}
        className={zoneEditorCanvasContainer}
      >
        <Canvas
          key={update}
          areas={areas}
          width={width}
          height={height}
          onUpdate={onUpdate!}
          areaToHighlightId={areaToHighlight?.id}
          onAreaHovered={_onAreaHovered}
          onAreaDoubleClick={_onAreaDoubleClick}
          selectedArea={currentArea!}
          data-test-id={'canvas'}
          isDisabled={isDisabled}
        />
        { expandable &&
          <div className={zoneEditorExpandButton}>
            <ExpandButton
              onClick={() => {
              setExpanded(!expanded)
              dispatch(appActions.setExpandedCanvas(!expanded))
            }} expanded={expanded}
            />
          </div>}
      </div>
    </div>
  </div>)
}

export default ZoneEditor
