import React, {DetailedHTMLProps, forwardRef, ImgHTMLAttributes, RefObject, useEffect, useState} from 'react'

import {random} from '@/domain/utils'

import styles from './styles.module.scss'

const {
  'zone-editor__video': zoneEditorVideo,
  'zone-editor__video--broken': zoneEditorVideoBroken,
} = styles

type Props = {
  src: string,
  errorHandler?: () => void,
  loadHandler?: () => void,
  isDisabled?: boolean
}

const Video = forwardRef(( {loadHandler, errorHandler, src, isDisabled}: Props, ref) => {
  const [refreshInterval, setRefreshInterval] = useState(0)
  const [source, setSrc] = useState('')
  const [isVideo, setIsVideo] = useState(false)
  const [broken, setBroken] = useState(false)

  const _errorHandler = () => {
    setBroken(true)
    errorHandler?.()
  }

  const isBlob = src && src.split(':').includes('blob')

  useEffect(() => {
    let isMounted = true

      try {
        let url = new URL(src)
        if (url.pathname === '/') {
          url = new URL('/image.jpg', url)
        }
        if (url.pathname === '/image.jpg') {
          setRefreshInterval(5000)
        }

        if (!isBlob) {
          fetch(url.toString(),
              {method: 'HEAD'})
              .then( resp => {
                const contentType = resp.headers.get('content-type')
                const isImage = contentType === 'multipart/x-mixed-replace' || contentType === 'image/jpeg'
                if (isMounted) {
                  setIsVideo(!isImage)
                }
              })
              .catch( () => {
                if (isMounted) {
                  setIsVideo(url.pathname.endsWith('.mp4'))
                }
              })
              .finally(() => {
                if (isMounted) {
                  setSrc(url.toString())
                }
              })
        } else {
          setSrc(src)
        }
      } catch(e) {
        if (isBlob) {
          console.error('Invalid source URL')
          _errorHandler()
        }
      }

    return () => {
      isMounted = false
    }
  }, [src])

  const videoClass = `${zoneEditorVideo} ${isDisabled ? 'blurred' : ''}`

  return <>
    {/* eslint-disable*/}
    { source
      ? broken
        ? <div
            ref={ ref as RefObject<HTMLDivElement> }
            className={ `${ zoneEditorVideo } ${ zoneEditorVideoBroken }` }>
              <span>The source is unavailable</span>
          </div>
        : isVideo
          ? <video
              data-test-id={ 'stream' }
              autoPlay={ true }
              className={ videoClass }
              ref={ ref as RefObject<HTMLVideoElement> }
              src={ source }
              muted={ true }
              preload={ 'metadata' }
              onError={ _errorHandler }
              onLoadedData={ loadHandler }
              style={isDisabled ? {pointerEvents: 'none', filter: 'blur(5px)'} : {}}
            />
            : <Image
                refreshInterval={ refreshInterval }
                src={ source }
                className={ videoClass }
                ref={ ref as RefObject<HTMLImageElement> }
                onError={ _errorHandler }
                onLoad={ loadHandler }
                isDisabled={isDisabled}
            />
      : ''
    }
    {/* eslint-enable*/}
  </>
})

type ImgProps = DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
  refreshInterval?: number,
  isDisabled?: boolean
}

const Image = forwardRef((props: ImgProps, ref) => {
  const {refreshInterval, isDisabled, src, ...other} = props
  const [url, setURL] = useState<string | undefined>(src)

  useEffect( () => {
    let interval = -1
    if (refreshInterval && refreshInterval > 0 && props.src) {
      const img = new URL(props.src)
      interval = window.setTimeout(() => {
        img.searchParams.set('hash', random(10,11).toString(36))
        setURL(img.toString())
      }, refreshInterval)
    }
    return () => {
      return clearTimeout(interval)
    }
  }, [refreshInterval, src, url])

  return <img
    data-test-id={'stream'}
    src={url}
    className={zoneEditorVideo}
    ref={ref as RefObject<HTMLImageElement>}
    {  ...other }
    alt={''}
    style={isDisabled ? {pointerEvents: 'none', filter: 'blur(5px)'} : {}}
  />
})

Video.displayName = 'Video'
Image.displayName = 'Image'

export default Video
