
export type IDot = {
  x: number,
  y: number
}

let i = 0

class Dot implements IDot {
  constructor(
    public x: number,
    public y: number,
    public id?: string) {
    if (id === undefined) {
      this.id = 'dot-' + i++
    }
  }
}
export default Dot
