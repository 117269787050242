import React from "react"
import {useSelector} from "react-redux"

import {CustomBreadcrumb} from "@/components"
import {TStore} from "@/store"

import {RoutePath} from "../providers/router-provider/router-config"

export const Breadcrumb = () => {
    const {setups, currentCamera} = useSelector((state: TStore) => state.setupsReducer)

    const item = [...location.pathname.split('/')
        .flat(1)
        .filter(v => v != '')
        .reduce((acc: { name: string, href: string}[], item) => {
            const path = location.pathname.split('/')

            if (path.includes('setups')) {
                if (item === 'setups') {
                    acc.push({name: item[0].toUpperCase() + item.slice(1), href: `/setups`})
                    if (path.length > 2) {
                        const elem = setups && setups.find((el) => {
                            return el.setup_id === Number(path[2])
                        })
                        acc.push({name: elem?.setup_name as string, href: `${RoutePath.all_setups}/${elem?.setup_id}`})
                        if (path.length === 4) {
                            acc.push({
                                name: `${currentCamera?.camera_location}`,
                                href: `${RoutePath.all_setups}/${elem?.setup_id}/${currentCamera?.camera_id}`
                            })
                        }
                    }
                }
            }
            return acc
        }, [])
    ]

    const isLogin = location.pathname === '/login'
    const isRestorePassword = location.pathname === '/restore-password'

    const showLogic = isLogin || isRestorePassword || item.length < 2

    return (<>
      {!showLogic ? <CustomBreadcrumb items={item} /> : null}
    </>
    )
}
