import {ConfigProvider,Table as AntdTable, TablePaginationConfig} from "antd"
import {ColumnsType} from "antd/es/table"
import {SorterResult} from "antd/es/table/interface"
import React from 'react'

type TableComponentProps<T extends object> = {
    columns: ColumnsType<T>
    data: T[],
    pagination?: false | TablePaginationConfig | undefined
    sortedFields?: string[],
    onRow?: (data: T, index?: number) => (React.HTMLAttributes<any> | React.TdHTMLAttributes<any>)
    rowClassName?: (data: T) => string

}
const Table = <T extends object>(props: TableComponentProps<T>) => {
    const {
        columns,
        data,
        sortedFields = [],
        pagination,
        onRow,
        rowClassName
    } = props
    const getComparator = (field: keyof T, order: SorterResult<T>['order']) => {
        return (a: T, b: T) => {
            const valueA = String(a[field]).toLowerCase()
            const valueB = String(b[field]).toLowerCase()

            if (valueA < valueB) {
                return order === 'ascend' ? -1 : 1
            }
            if (valueA > valueB) {
                return order === 'ascend' ? 1 : -1
            }
            return 0
        }
    }

    const sortedColumns = columns.map(column => {
        const key = column.key as string
        if (sortedFields.includes(key)) {
            return {
                ...column,
                sorter: getComparator(column.key as keyof T, 'ascend'),
            }
        }
        return column
    })

    return (
      <ConfigProvider
        theme={{
                token: {
                    colorPrimary: 'E20074FF'
                }
            }}
      >
        <AntdTable
          columns={sortedColumns}
          dataSource={data}
          rowKey={'id'}
          pagination={pagination}
          onRow={onRow}
          rowClassName={rowClassName}
        />
      </ConfigProvider>
    )
}

export default Table
