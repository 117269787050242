import {ScaleButton} from "@telekom/scale-components-react"
import React, {ReactNode} from "react"

import CustomIcon from "../custom-icon"
import styles from './styles.module.scss'

const {
  'custom-modal': customModal,
  'custom-modal__header': modalHeader,
  'custom-modal__title': modalTitle,
  'custom-modal__content': modalContent,
  'custom-modal__actions': modalActions,
  'custom-modal__button': modalButton,
  'custom-modal__overlay': overlay
} = styles

type modalTypes = {
  title: string,
  content: ReactNode
  onAction?: (action:'ok'| 'cancel') => void
  width?: 'small' | 'medium' | 'large'
  position?: 'center' | 'bottom' | 'top'
  okContent?: string
  cancelContent?: string
  isDisabled?: boolean
  isDisabledOverlay?: boolean
  isShowClose?: boolean
  className?: string
}

const CustomModal = (Props: modalTypes) => {
  const {
    title, content, onAction, position = 'center', width = 'small', className,
    isDisabled, isShowClose = true, okContent = 'OK', cancelContent = 'Cancel', isDisabledOverlay
  } = Props

  const setPositionStyle = () => {
    switch (position) {
      case 'center':
        return {top: '50%'}
      case 'bottom':
        return {top: '85%'}
      case 'top':
        return {top: '15%'}
      default:
        return {top: '50%'}
    }
  }

  const setWidthStyle = () => {
    switch (width) {
      case 'small':
        return null
      case 'medium':
        return {width: '60%'}
      case 'large':
        return {width: '90%'}
      default:
        return null
    }
  }

  return (
    <>
      {isDisabledOverlay && <div className={overlay} />}
      <div className={`${customModal} ${className}`} style={{...setPositionStyle(), ...setWidthStyle()}}>
        <div className={modalHeader}>
          <h3 className={modalTitle}>{title}</h3>
          {isShowClose && <div onClick={() => onAction ? onAction('cancel') : null}>
            <CustomIcon name={'close_cross'} size={'16px'} className={modalButton} />
            </div>}
        </div>
        <div className={modalContent}>
          {content}
        </div>
        {onAction ?<div className={modalActions}>
          {isShowClose &&
          <ScaleButton variant={'secondary'} onClick={() => onAction ? onAction('cancel') : null}>{cancelContent}</ScaleButton>}
          <ScaleButton
            variant={'primary'} disabled={isDisabled}
            onClick={() => onAction ? onAction('ok') : null}
          >{okContent}</ScaleButton>
        </div> : null}
      </div>
    </>
  )
}

export default CustomModal
