import {ScaleButton} from "@telekom/scale-components-react"
import React, {ErrorInfo} from "react"

import styles from "./styles.module.scss"

const {
    'page-error': pageError,
    'page-error__wrapper': errorWrapper,
    'page-error__message': errorMessage,
} = styles

type PageErrorType = {
    error: Error | null,
    errorInfo: ErrorInfo | null
}

const PageError = ({error, errorInfo}: PageErrorType) => {
    const reloadPage = () => {
        // eslint-disable-next-line no-restricted-globals
        location.reload()
    }

    console.log({error, errorInfo})

    return (
      <div className={pageError}>
        <p>{('Error Info Message')}</p>
        <div className={errorWrapper}>
          <div className={errorMessage}>{error?.message}</div>
          <div>{error?.stack}</div></div>
        <ScaleButton
          variant={"primary"}
          slot={'action'}
          onClick={reloadPage}
        >
          Update Page
        </ScaleButton>
      </div>
    )
}

export default PageError
