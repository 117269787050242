import {Dispatch} from "@reduxjs/toolkit"

import {doRequest} from "@/domain/api"
import {del} from "@/domain/http"
import {Setup} from "@/domain/types/setup"

import RequestError from "../../../exceptions/request-error"
import {appActions} from "../../slices/app-slice"

export const fetchDeleteSetup = async (dispatch: Dispatch, id: number) : Promise<Setup|undefined> => {
    try {
        dispatch(appActions.setLoading(true))

        await doRequest<Setup>(
            del<Setup>(`setup/${id}`),
            (message, detail) =>
                new RequestError('delete', `Cannot delete setup`, detail),
        )

        dispatch(appActions.setLoading(false))
    } catch (error) {
        dispatch(appActions.setError(error.message))
        dispatch(appActions.setLoading(false))

        console.log(error)
        return undefined
    }
}
