import React, {FunctionComponent} from 'react'

import {
  CalendarIcon, CameraIcon,   CheckboxCheckedIcon, CheckboxUncheckedIcon, CloseCrossIcon,
  CloseIcon, CogIcon, DeleteIcon, DotsIcon, EditIcon, GridViewIcon,   HelplessPersonIcon, InfoIcon,ListViewIcon,
  LogoIcon, LuggageIcon, PersonRestrictedAreaIcon, PlayIcon, PublicTransportIcon, QuestionIcon, RemoveLastIcon,
  ReopenIcon, SetupIcon, StopIcon, ToolsIcon} from '../../icons'
import styles from './style.module.scss'

const {
  'custom-icon-disabled': disabled_class
} = styles

type IconProps = {
  name?: string,
  size?: string,
  fill?: string,
  className?: string,
  disabled?: boolean
}

type FC<P = {}> = FunctionComponent<P>

const CustomIcon = ({
  size = '24px', fill = 'currentColor', name = 'default', className = '', disabled
}: IconProps) => {
  const iconProps = {width: size, height: size, fill, className}
  const Icon: FC<IconProps> = {
    close: CloseIcon,
    stop: StopIcon,
    reopen: ReopenIcon,
    remove: ReopenIcon,
    'remove_last': RemoveLastIcon,
    'checkbox_checked': CheckboxCheckedIcon,
    'checkbox_unchecked': CheckboxUncheckedIcon,
    'close_cross': CloseCrossIcon,
    'helpless_person': HelplessPersonIcon,
    'person_restricted_area': PersonRestrictedAreaIcon,
    'public_transport': PublicTransportIcon,
    tools: ToolsIcon,
    question: QuestionIcon,
    luggage: LuggageIcon,
    'grid_view': GridViewIcon,
    'list_view': ListViewIcon,
    'setup': SetupIcon,
    edit: EditIcon,
    delete: DeleteIcon,
    camera: CameraIcon,
    setting: CogIcon,
    dots: DotsIcon,
    play: PlayIcon,
    calendar: CalendarIcon,
    logo: LogoIcon,
    info: InfoIcon
  }[name]!

  return (
    <span className={`${className} ${disabled ? null : disabled_class}`}>
      <Icon {...iconProps} />
    </span>
  )
}

export default CustomIcon
