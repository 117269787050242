import '@telekom/scale-components/dist/scale-components/scale-components.css'
import './index.scss'
import './i18n/config/i18n'

import {applyPolyfills, defineCustomElements} from '@telekom/scale-components/loader'
import React, {Suspense} from 'react'
import {createRoot} from 'react-dom/client'
import {Provider} from "react-redux"

import {ErrorBoundary, PageLoader} from "@/components"
import {store} from "@/store"

import Root from './root'

applyPolyfills().then(() => {
  defineCustomElements(window)
})

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <ErrorBoundary>
    <Suspense fallback={<PageLoader />}>
      <Provider store={store}>
        <Root />
      </Provider>
    </Suspense>
  </ErrorBoundary>)
