import React from 'react'

import styles from './stepper.module.scss'

type Props = {
  name: string
  number: number
  isActive: boolean
}

const {
  stepper__label: wrapperStyle,
  'stepper__label--active': wrapperActiveStyle,
  'stepper__label-first': firstLineStyle,
  'stepper__label-second': secondLineStyle
} = styles

const Step = ({name, number, isActive}: Props) => {
  const classes = `${wrapperStyle} ${isActive ? wrapperActiveStyle : ''}`

  return <div className={classes}>
    <div className={firstLineStyle}>Step {number}</div>
    <div className={secondLineStyle}>{name}</div>
  </div>
}

export default Step
