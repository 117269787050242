import Dot, {IDot} from '../dot'
import RoIType from '../roi-type'
import Coordinates from './coordinates'
import RoI, {IRoI, RoIBase} from './roi'

abstract class RoiEditable<T = Coordinates> extends RoIBase<T> {
  protected finished = true

  constructor( private roi: IRoI<T>) {
    super( roi.id, roi.name, roi.color, roi.coordinates )
    this.init(roi)
  }

  protected init(roi: IRoI<T>) {
    this.coordinates = this.getCoordinatesWithIds(roi.coordinates)
    this.finished = true
  }

  abstract getCoordinatesWithoutIds(): T

  abstract getCoordinatesWithIds(coordinates: T): T

  abstract addDot( dot: Dot ): void

  abstract removeDot( dot: Dot ): void

  abstract removeLastDot(): void

  abstract canAddNewDots(): boolean

  abstract canFinish(): boolean

  abstract isFinished(): boolean

  abstract getLength(): number

  abstract clear(): void

  public setName(name: string) {
    this.name = name
  }

  public setColor( color: string ): void {
    this.color = color
  }

  public freeze(): void {
    this.finished = true
  }

  public unfreeze(): void {
    this.finished = false
  }

  getType(): RoIType {
    return this.roi.type
  }

  abstract save(): RoI<T>

  protected createIds( dots: IDot[] ) {
    return dots.map( ( dot, i ) => new Dot( dot.x, dot.y, this.roi.id + '-dot-' + i ) )
  }

  protected remove( dots: Dot[], dot: Dot ) {
    const index = dots.findIndex( _dot => _dot.id === dot.id )
    if ( index >= 0 ) {
      dots.splice( index, 1 )
    }
  }

  abstract replaceDot( dot: Dot ): void

  protected replaceDotInList( dots: Dot[], dot: Dot ) {
    const index = dots.findIndex( _dot => _dot.id === dot.id )
    if ( index >= 0 ) {
      dots.splice( index, 1, dot )
    }
  }
}

export default RoiEditable
